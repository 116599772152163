/*Used to center the cards within flexbox*/
.ant-row.myrow {
    justify-content: center !important;
  }

/*Fix up cards and display animation on hover (marketplace NFT cards)*/
.ant-card-bordered {
  color: #000000 !important;
  transition: all .2s ease-in-out;
}
.ant-card-bordered:hover {
  /*background-color: #051221 !important;*/
  transform: scale(1.05);
  color: #ffffff !important;
}


