.mobile-boomroom-logo{
    width: 100%;
}

.mobile-br-htp{
    width: 360px;
}

.mobile-br-page-title{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 40px;
}

.mobile-br-menu-button{
    width:170px;
    height: 100px;
    text-align: center;
    margin: 5px 5px 50px 0px;
    font-size: 22px;
}

.mobile-br-subtitle{
    color:#f1f1f1;
    font-size: 30px;
    font-family: nowbold;
    text-align: center;
    margin-top: 20px;
}

.mobile-br-roomconstruct-image{
    width: 345px;
    height: 345px;  
}

.mobile-br-roomconstruct-stats{
    width: 330px;
}

.mobile-br-subsubtitle{
    color:#f1f1f1;
    font-size: 20px;
    font-family: nowbold;
    text-align: center;
    margin-top: 10px;
}

.mobile-br-construction-spinner-box{
    min-height: 200px;
}

.mobile-br-compbuilder-select-buttons{
    width:150px;
    font-size:20px;
}

.mobile-br-roomdecorator-roomimage{
    width: 333px;
    height: 333px;
    position: relative;
  }

.mobile-br-roomdecorator-roomcomponents{
    width: 360px;
    height: 100%;
    margin-top:20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.mobile-br-upgradecomponenet-square{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 350px;
}

.mobile-br-upgradecomponenet-square-side{
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

.mobile-br-upgradecomponenet-square-name{
    /* position:absolute; */
    width:100%;
    color:#f1f1f1;
    font-size: 30px;
    font-family: nowbold;
    text-align: center;
    height: 100px;
  }

.mobile-br-upgradecomponenet-square-image{
    width: 350px;
    height: 350px;
}

.mobile-br-componentupgrade-image {
    width: 350px;
    height: 350px;
    background-color: black;
  }

.mobile-br-upgradecomponenet-square-info{
    font-family: nowbold;
    width: 350px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;
  }

  .mobile-br-traitlist{
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 100px;
    padding: 5px;
    border-radius: 20px;
    margin: 5px;
  }
.mobile-br-upgradecomponenet-square-arrow{
    font-size: 100px;
}