.cc-verify-button {
    background-color: #4CAF50; /* Green color */
    border: none;
    color: white; /* White text color */
    padding: 10px 20px; /* Padding around the text */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px; /* Font size */
    margin: 4px 2px;
    cursor: pointer; /* Cursor will change to a hand */
    border-radius: 12px; /* Rounded corners */
    transition: 0.3s; /* Transition effect */
}

.cc-verify-button:hover {
    background-color: #45a049; /* Slightly different green on hover */
}

.cc-small-text {
    font-size: 16px; /* You can adjust the size to your preference */
    font-weight: 400; /* Lighter font weight */
}
  
.cc-unverify-button {
    background-color: #F44336; /* Red color */
    border: none;
    color: white; /* White text color */
    padding: 10px 20px; /* Padding around the text */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px; /* Font size */
    margin: 4px 2px;
    cursor: pointer; /* Cursor will change to a hand */
    border-radius: 12px; /* Rounded corners */
    transition: 0.3s; /* Transition effect */
}

.cc-unverify-button:hover {
    background-color: #d32f2f; /* Slightly different red on hover */
}
