
.anti-phishing-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #f5c6cb;
    opacity: 1;
    height: 75px;
    transition: all 0.3s ease-in-out;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .anti-phishing-banner-text-area{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
  }
  
  .anti-phishing-banner.hidden {
    margin-top: -75px;
    opacity: 0;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
    color: #721c24;
  }
  
  .close-button:focus {
    outline: none;
  }
  
  @media (max-width: 1200px) {
    .anti-phishing-banner {
      font-size: 11px;
      padding: 5px;
    }
    .close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: bold;
        background: none;
        border: none;
        cursor: pointer;
        color: #721c24;
      }
  }