.playground-background-cover {
  background-repeat: repeat;
  background-image: url('./playbg50.png');
  background-size: 40%;
  font-family: nowbold;
}

.playground-poglist-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.playground-title {
  color: white;
  font-size: 30px;
}

.playground-section {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.822);
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  margin: 10px 0px;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  min-width: 355px;
}

.playground-button {
  background-color: #066000;
  border-radius: 10px;
  border: 1px white solid;
  color: white;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  cursor: pointer;
  transition: 0.2s;
}

.playground-button.inactive {
  background-color: #2c2c2c;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pagination-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  margin: 3px 3px;
  width: 30px;
  height: 40px;
  transition: all 0.2s;
  border-radius: 10px;
}

.pagination-button:hover {
  background-color: #e0e0e0;
}

.pagination-button.active {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.pagination-button.active:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

/* App.css */
.scoreboard {
  background-color: #090909;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%;
  max-width: 700px;
  margin: 20px 0px;
  height: 100%;
  color: white;
  border: 6px rgb(0, 145, 161) groove;
}

.scoreboard h2 {
  text-align: center;
  color: white;
  font-size: 26px;
}

.scoreboard .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
}

.scoreboard h3 {
  text-align: center;
  color: white;
  font-size: 12px;
}

.scoreboard p {
  display: flex;
  justify-content: space-between;
  outline: 2px rgba(255, 166, 0, 0.541) dashed;
  border-radius: 4px;
  height: 100%;
  align-items: center;
  padding: 5px;
  margin: 2px 0px;
  font-size: 12px;
}

.scoreboard .section {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #000000;
  padding: 4px;
  border: 3px rgb(106, 106, 106) solid;
  gap: 4px;
}

.theplayground-pogs-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  padding-bottom: 20px;
}

.theplayground-poglist {
  flex: 1;
  text-align: center;
  background-color: #090909e4;
  max-width: 600px;
  min-width: 300px;
  border: 5px double;
  border-radius: 10px;
  min-height: 500px;
}

.theplayground-list-title {
  color: white;
  font-size: 30px;
  text-align: center;
  padding: 10px;
}

.theplayground-pog-image-container {
  width: 100px;
  height: 100px;
}

.playgroundstats-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(231, 231, 231);
  max-width: 1400px;
  min-width: 350px;
  min-height: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0px;
  font-size: 12px;
}

.playgroundstats-bar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.381);
}

.playgroundstats-bar-row-address {
  color: rgb(52, 52, 52);
  font-size: 10px;
  font-style: italic;
}

.playgroundstats-bar-row-nums {
  display: flex;
  justify-content: center;
  color: black;
  width: 100%;
}

.stats-span {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid black;
}

.playgroundstats-bar {
  height: 10px;
}

.playtable {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 9px;
  width: 100%;
}

.playrow {
  display: table-row;
  width: 100%;
}

.playcell {
  display: table-cell;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  max-width: 150px;
  min-width: 150px;
}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Digital-7 Mono', sans-serif;
  font-size: 24px;
  background-color: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #04db00;
  margin-top: 5px;
  border: dashed 1px #005e19;
}

.separator {
  margin: 0 5px;
}

@font-face {
  font-family: 'digital-7 Mono';
  src: url('../../public/fonts/digital-7 Mono.ttf') format('truetype');
}


@media (max-width: 1279px) {
  .scoreboard .content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .playcell {
    max-width: 50px;
    min-width: 50px;
  }

  .scoreboard h3 {
    font-size: 14px;
  }

  .scoreboard p {
    font-size: 12px;
  }

  .theplayground-pog-image-container {
    width: 80px;
    height: 80px;
  }
}