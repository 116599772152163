.pawnshop-background{
    background: #051221;
    /* background: linear-gradient(90deg, rgb(105, 33, 116) 0%, rgb(125, 41, 113) 50%, rgb(160, 98, 157) 100%); */
}

.pawnshop-menu-button{
    max-width: 1200px;
    min-width: 300px;
    width: 90%;
    border-radius: 50px;
    box-shadow: 8px 10px #000000;
}

.mobile-pawnshop-menu-button{
    width: 350px;
    border-radius: 20px;
    box-shadow: 8px 10px #000000;
}

.pawnshop-purchase-scratch-button{
    background-color: rgba(0, 191, 255, 0.5);
    color: white;
    border-radius: 100px;
    border-style: solid;
    border-color: aliceblue;
    border-width: 3px;
    padding: 40px;
    font-size: 60px;
    font-family: nowbold;
    box-shadow: 7px 10px rgb(0, 0, 0, 30%);
}

.mobile-pawnshop-purchase-scratch-button{
    background-color: rgba(0, 191, 255, 0.5);
    color: white;
    border-radius: 30px;
    border-style: solid;
    border-color: aliceblue;
    border-width: 1px;
    padding: 20px;
    font-size: 25px;
    font-family: nowbold;
    box-shadow: 7px 10px rgb(0, 0, 0, 30%);
}

.shadow-button{
    box-shadow: 3px 13px #000000;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.shadow-button:hover{
    transform: translateY(11px);
    box-shadow: 0px 2px #000000;
    transition: all .1s ease-in-out;
}

.shadow-button-w{
    box-shadow: 0px 9px rgba(255, 255, 255, 0.61);
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.shadow-button-w:hover{
    transform: translateY(11px);
    box-shadow: 0px 1px rgba(255, 255, 255, 0.61);
    transition: all .1s ease-in-out;
}

.pawnshop-default-button{
    background-color: black;
    border-radius: 20px;
    padding: 20px;
    font-size: 25px;
    font-family: nowbold;
}

.pawnshop-raffle-card{
    font-family: nowbold;
    font-size: 15px;
    width: 700px;
    color: white;
    border-radius: 10px 10px 10px 10px;
    border-width: 1px;
    background: linear-gradient(0deg, rgb(8, 8, 53), rgb(22, 60, 143) 50%, rgb(5, 151, 174) 100%);
    box-shadow: 9px 12px rgb(0, 0, 0, 30%);
    text-align: center;
}

.mobile-pawnshop-raffle-card{
    font-family: nowbold;
    font-size: 13px;
    width: 350px;
    color: white;
    border-radius: 10px 10px 10px 10px;
    border-width: 1px;
    background: linear-gradient(0deg, rgb(8, 8, 53), rgb(22, 60, 143) 50%, rgb(5, 151, 174) 100%);
    box-shadow: 9px 12px rgb(0, 0, 0, 30%);
    text-align: center;
}

.pawnshop-raffle-card-row{
    display:flex;
    width: 100%;
    align-items: baseline;
    font-size: 15px;
}

.mobile-pawnshop-raffle-card-row{
    display:flex;
    width: 100%;
    align-items: baseline;
    font-size: 12px;
}

.opacity-50{
    opacity: 40%;
}

.pawnshop-scratch-button{
    background-color: #000000;
    width: 150px;
    border-radius: 100px;
    border-color: aliceblue;
}

.mobile-pawnshop-scratch-button{
    background-color: #000000;
    width: 90px;
    border-radius: 100px;
    border-color: aliceblue;
}

.pawnshop-scratch-purchase{
    transform: perspective(10px);

}

.pawnshop-raffle-card-prizes{
    gap: 20px;
    margin: 10px;
    width: 100%;
    background: linear-gradient(90deg, rgb(9, 84, 25), rgb(25, 103, 15) 50%, rgb(9, 84, 25) 100%);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 4px 7px rgb(0, 0, 0, 30%);
}

.mobile-pawnshop-raffle-card-prizes{
    gap: 15px;
    margin: 2px;
    width: 100%;
    background: linear-gradient(90deg, rgb(9, 84, 25), rgb(25, 103, 15) 50%, rgb(9, 84, 25) 100%);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 4px 7px rgb(0, 0, 0, 30%);
}

.pawnshop-raffle-tickets-button{
    border-radius: 20px;
    border-color: #000000;
    border-style: solid;
    border-width: 1px;
    font-size: 25px;
    padding: 10px 20px;
    width: 260px;
}

.pawnshop-raffle-tickets-button.buy{
    background-color: rgb(195, 27, 27)
}

.pawnshop-raffle-tickets-button.gift{
    background-color: rgb(14, 143, 14)
}

.mobile-pawnshop-raffle-buy-tickets-button{
    background-color: rgb(201, 36, 198);
    border-radius: 10px;
    border-color: #000000;
    border-style: solid;
    border-width: 1px;
    font-size: 20px;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bingo-game-button{
    background-color: rgb(44, 118, 124);
    border-radius: 15px;
    border-color: #001c76;
    border-style: solid;
    border-width: 2px;
    font-size: 20px;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 75px;
    height: 75px;
    font-size: 40px;
}

.bingo-game-button.selected{
    background-color: rgb(204, 63, 255);
}

.bingo-boomer-button{
    background-color: rgb(189, 113, 0);
    border-radius: 10px;
    border-color: #001c76;
    border-style: solid;
    border-width: 2px;
    font-size: 20px;
    margin: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

.bingo-boomer-button.selected{
    background-color: rgb(0, 52, 184);
}

.bingo-select-game{
    background-color: rgb(30, 111, 0);
    border-radius: 20px;
    margin: 30px;
    padding: 50px;
    box-shadow: 10px 10px 10px #000000;
    gap: 20px;
}

.mobile-bingo-select-game{
    background-color: rgb(30, 111, 0);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 10px 10px 10px #000000;
    gap: 20px;
}

.bingo-card{
    display: flex;
    flex-direction: row;
    height: 700px;
    width: 700px;
    gap: 5px;
}

.mobile-bingo-card{
    display: flex;
    flex-direction: row;
    height: 340px;
    width: 340px;
    gap: 3px;
}

.bingo-square{
    flex: 1;
    width: 100%;
    height: 100%;
}

.bingo-column{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 5px;
}

.square{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #cae8eb;
    font-size: 20px;
    position: relative;
}

.square.iswin{
    border-color: green;
    border-width: 3px;
    border-style: solid;
}

.bingo-bingo-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.mobile-bingo-bingo-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.bingo-card-letter{
    font-size: 50px;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-shadow: 2px 2px 2px black;
    border-color: rgb(104, 0, 0);
    border-style: solid;
    border-radius: 5px;
    border-width: 2px;
    height: 140px;
}

.bingo-card-letter-small{
    text-align: center;
    font-size: 15px;
    width: 100%;
    padding: 2px;
}

.bingo-card-letter.orange{
    background-color: orange;
    color: white;
}

.bingo-card-letter.Green{
    color: green;
}

.bingo-card-letter.Blue{
    color: blue;
}

.bingo-card-letter.Purple{
    color: purple;
}

.bingo-card-letter.Yellow{
    background-color: rgb(215, 215, 0);
    color: white;
}

.bingo-full-card{
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 10px;
    border-radius: 10px;
    background-color: antiquewhite;
    color: black;
}

.mobile-bingo-full-card{
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: antiquewhite;
    color: black;
    width: 350px;
}

.small-shadow-button{
    box-shadow: 0px 4px #000000;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.small-shadow-button:hover{
    transform: translateY(3px);
    box-shadow: 0px 1px #000000;
    transition: all .1s ease-in-out;
}

.bingo-boomer-image{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    box-shadow: 1px 2px black;
}

.bingo-title{
    color: white;
    font-size: 50px;
    text-align: center;
}

.mobile-bingo-title{
    color: white;
    font-size: 25px;
    text-align: center;
}

.bingo-square-number{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border-color: #000000;
    border-width: 2px;
    border-style: solid;
    z-index: 2;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.mobile-bingo-square-number{
    height: 25px;
    width: 25px;
    border-radius: 5px;
    border-color: #000000;
    border-width: 2px;
    border-style: solid;
    z-index: 2;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}

.orange-square{
    background-color: orange;
    color: white;
}

.green-square{
    background-color: green;
}

.blue-square{
    background-color: blue;
}

.purple-square{
    background-color: purple;
}

.yellow-square{
    background-color: rgb(139, 139, 0);
    color: white;
}

.bingo-ball{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
}

.bingo-balls-drawn{
    background-color: #00473f;
    border-radius: 20px;
    width: 90%;
    padding: 40px;
    gap: 40px;
    box-shadow: 10px 10px 10px black;
}

.mobile-bingo-balls-drawn{
    background-color: #00473f;
    border-radius: 20px;
    padding: 30px;
    gap: 40px;
    box-shadow: 10px 10px 10px black;
}

.bingo-ball-row{
    gap: 20px;
}
  
.check {
    transform: rotate(45deg);
    height: 40px;
    width: 20px;
    border-bottom: 10px solid #00ff1a;
    border-right: 10px solid #00ff1a;
    top: 50%;
    left: 50%;
}

.mobile-check {
    transform: rotate(45deg);
    height: 20px;
    width: 10px;
    border-bottom: 5px solid #00ff1a;
    border-right: 5px solid #00ff1a;
    top: 50%;
    left: 50%;
}

.bingo-check-cirle{
background-color: rgb(22, 22, 22);
border-radius: 100px;
z-index: 3;
height: 50%;
width: 50%;
border: 2px solid #00ff1a;
}

.bingo-square-numbers-row{
width: 100%;
justify-content: space-between;
}

.bingo-approve-button{
    border: 2px solid #02003b;
    background-color: rgb(0, 136, 255);
    border-radius: 40px;
    padding: 20px;
}

.mobile-bingo-approve-button{
    border: 2px solid #02003b;
    background-color: rgb(0, 136, 255);
    border-radius: 40px;
    padding: 20px;
    font-size: 30px;
}

.bingo-card-number{
    font-size: 25px;
}

.bingo-background{
    background-image: url('./bingobg50.png');
    background-size: 100%;
}

.scratcher{
    height: 400px;
    width: 400px;
}

.mobile-scratcher{
    height: 300px;
    width: 300px;
}

.scratcher-row{
    gap: 30px;
    margin: 60px;
}

.scratcher-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    background-color: antiquewhite;
    border-radius: 40px;
    gap: 10px;
    padding: 30px;
    font-size: large;
    font-family: nowbold;
    margin: 20px;
}

.mobile-scratcher-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 355px;
    background-color: antiquewhite;
    border-radius: 40px;
    gap: 10px;
    padding: 20px;
    font-size: medium;
    font-family: nowbold;
    margin: 30px;
}

.scratcher-name{
    font-size: 30px;
    font-family: nowbold;
    text-shadow: 1px 1px 0px black;
}

.scratcher-name.bronze{
    color: rgb(165, 101, 42);
}

.scratcher-name.silver{
    color: rgb(70, 70, 70);
}

.scratcher-name.gold{
    color: rgb(157, 144, 7);
}

.scratcher-section{
    /* justify-content: space-between; */
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    align-items: baseline;

  }

.span2{
    flex: 1;
    width: 100%;
    border-bottom-color: rgb(0, 0, 0);;
    border-bottom-width: 2px;
    border-bottom-style: dotted;
  }

.scratcher-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    width: 310px;
    font-size: 27px;
    color:#e7e7e7;
}

.scratcher-button.bronze{
    background-color: rgb(165, 101, 42);
}

.scratcher-button.silver{
    background-color: rgb(70, 70, 70);
}

.scratcher-button.gold{
    background-color: rgb(157, 144, 7);
}

.scratcher-disclaimer{
    font-size: 13px;
    margin-top: 15px;
}

.raffle-notice{
    background-color: antiquewhite;
    border: #0692a5 3px solid;
    color: #000000;
    font-family: nowbold;
    padding: 20px;
    font-size: 20px;
    border-radius: 10px;
    text-align: center;
}

.m-raffle-notice{
    background-color: antiquewhite;
    border: #0692a5 3px solid;
    color: #000000;
    font-family: nowbold;
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;
    text-align: center;
}

.raffle-label{
    font-size: 20px;
    margin-right: 5px;
}

.raffle-address-input{
    color:#000000
}