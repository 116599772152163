:root {

    --width-one: 450px;
    --width-two: 250px;

    --height-one: 250px;
    --height-two: 300px;
    --height-three: 150px;

    --width-three: 120px;
    --height-four: 150px;

    --border-one: 1px;

    --border-radius-one: 10px;

    --background-one: #0b1e32;
    --background-two: rgb(189, 94, 6);
    --background-three: rgb(25, 116, 0);

    --duration-one: 0.3s;

    --borderRadius-one: 10px;
    --borderRadius-two: 10px;

    --padding-one: 15px;

    --margin-one: 30px;
  }

  @keyframes slideFromTop {
    0%   {transform:translateY(-40px)}
    100% {transform:translateY(0%)}
  }

.bazaar-main{
    font-family: nowbold;
    font-size: 20px;
    color: aliceblue;
}

.bazaar-items-container{
    /* outline: var(--margin-one) solid var(--background-one); */
    /* padding: var(--margin-one); */
    border-top-left-radius: var(--borderRadius-one);
    width: 100%;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    gap: 10px;
    padding: 0px 10px 10px 10px;
}

.bazaar-item{
    height: var(--height-one);
    outline: var(--border-one) solid #051221;
    background-color: rgb(240, 254, 255);
    display: flex;
    color: black;
    max-width: 100%;
}

.bazaar-item-image{
    max-width: var(--height-one);
    max-height: var(--height-one);
}

.bazaar-item-image-container{
    width: var(--height-one);
    height: var(--height-one);
    min-width: var(--height-one);
    min-height: var(--height-one);
    background-color:  rgb(236, 236, 236);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bazaar-item-info-container{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bazaar-filter-container{
    display: flex;
    width: var(--width-two);
    height: 100%;
    padding: var(--padding-one);
    /* margin-top: calc(0px - var(--margin-one)); */
    background-color: var(--background-two);
    border-radius: var(--border-radius-one);
}

.bazaar-filters{
    display: flex;
    flex-direction: column;
    width: 360px;
    z-index: 10;
    gap:10px;
}

.bazaar-layout{
    display: flex;
    width: 100%;
    min-height: 1000px;
    padding: 10px;
}

.bazaar-top{
    background-color: var(--background-one);
    border-bottom-right-radius: var(--borderRadius-one);
    padding: 60px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    gap:10px;
    box-shadow: inset 0px 10px 20px rgba(0,0,0,0.9);
    color: white;
}

.filter-arrow{
    height: .5em;
    width: .5em;
    border-top: rgb(218, 218, 218, 0.7) 3px solid;
    border-left: rgb(218, 218, 218, 0.7) 3px solid;
    transform: rotate(225deg);
    transition: all var(--duration-one);
}

.filter-arrow.clicked{
    transform: rotate(405deg);
}

.filter-title-container{
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 100%;
    background-color: var(--background-one);
    border-radius: var(--border-radius-one);
}

.filter-subtitle-container{
    display: none;
    background-color: #051221;
    padding: 15px;
    border-radius: var(--border-radius-one);
    gap: 10px;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    font-size: 18px;
}

.filter-subtitle-container.clicked{
    display: flex;
    /* animation-name: slideFromTop;
    animation-duration: var(--duration-one); */
}

.filter-title-row{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-size: 25px;
    z-index: 9;
    /* cursor: pointer; */
    padding: 15px;
}

.filt-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.filt-square-box{
    height: 1em;
    width: 1em;
    border: 1px solid white;
    box-shadow: inset 0px 0px 2px rgb(0, 0, 0);
    background-color: rgb(44, 44, 44);
    border-radius: var(--border-radius-one);
    cursor: pointer;
}

.filt-square-box:active{
    transform: scale(1.2);
}

.filt-square-box.filled{
    height: 1em;
    width: 1em;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: inset 0px 0px 2px rgb(0, 0, 0);
    background-color: rgb(45, 180, 0);
}

.filt-square-box:hover{
    box-shadow: inset 0px 0px 14px rgb(229, 252, 255);
    border: 3px solid rgb(0, 164, 8);
}

.filt-numbox-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.bazaar-item-info-name{
    overflow-wrap: break-word;
    color:rgb(0, 0, 0);
    cursor: pointer;
    text-decoration: underline;
}

.bazaar-item-info-name:hover{
    color:rgb(36, 36, 255);
}

.bazaar-item-info-stock{
    color:rgb(171, 0, 0);
    font-size: 15px;
}

.bazaar-item-info-price{
    color:rgb(0, 0, 0);
    font-size: 25px;
}

.bazaar-item-info-brand{
    color:rgb(0, 139, 137);
    font-size: 15px;  
}

.bazaar-item-info-wl{
    color:rgb(171, 0, 0);
    font-size: 15px;   
}

.bazaar-item-info-type{
    color:rgb(97, 97, 97);
    font-size: 15px;
    font-style: italic;
}

.sort-drop-down-container{
    position: relative;
}

.sort-dropdown {
    position: relative;
    display: inline-block;
    color: white;
    font-family: nowbold;
    font-size: 20px;
    cursor: pointer;
    padding: 6px 20px;
    border-radius: var(--border-radius-one);
    border: 1px solid black;
    background-color: rgb(43, 142, 7);
  }

.sort-dropdown:hover {
    background-color: rgb(24, 77, 5);
}

.sort-dropdown-content {
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: -0px;
    width: 260px;
    z-index: 1;
}

.sort-dropdown-visible{
    padding: 12px 16px;
    border-radius: var(--border-radius-one);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: rgb(3, 10, 17);
}

.sort-dropdown:hover .sort-dropdown-content {
    display: block;
}

.sort-dropdown-item:active .sort-dropdown-content {
    display: none;
}

.sort-dropdown-blue-arrow{
    width: 0; 
    height: 0; 
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid rgb(3, 10, 17, 97%);
}

.sort-dropdown-item{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: nowbold;
    font-size: 20px;
    border-radius: var(--border-radius-one);
    height: 50px;
    width: 100%;
}

.sort-dropdown-item:hover{
    background-color: rgba(255, 255, 255, 0.1);
}

.sort-dropdown-item:active .sort-dropdown-content{
    display:none;
}

.sort-dropdown-invisible{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 10px;
}

.sort-option{
    background-color: rgba(128, 128, 128, 0.5);
    border: 2px solid grey;
    padding: 3px 15px;
    border-radius: var(--border-radius-one);
    font-size: 20px;
}

.prediction-address{
    background-color: white;
    color: rgb(0, 0, 0, 0.6);
    border: 1px solid grey;
    font-size: 13px;
    padding: 3px;
    width: 500px;
    border-radius: 3px;
}

.prediction-address:hover{
    background-color: rgb(131, 255, 154);
    color: black;
    border: 1px solid grey;
    cursor: pointer;
    
}

.prediction-address.visible{
    display: block;
}

.prediction-address.invisible{
    display: none;
}

.pretty-input{
    border-radius: 4px;
    border: none;
    padding: 3px;
    transition: all 0.3s;
    border-width: 0.115em;
    border-style: solid;
    border-color:rgb(255, 255, 255)!important;
}

.address-field-container{
    position: relative;
}

.pretty-input-address{
    border-radius: 4px;
    border: none;
    padding: 3px;
    transition: all 0.3s;
    border-width: 0.15em;
    border-style: solid;
    border-color:rgb(255, 255, 255)!important;
    max-width: 500px;
    min-width: 340px;
}



.pretty-input.invalid{
    border-color:rgb(207, 0, 0)!important;
}

.pretty-input.valid{
    border-color:rgb(33, 163, 0)!important;
}

.pretty-input.name{
    width: 235px;
}

.pretty-input:focus{
    box-shadow: inset 0px 0px 2px rgb(196, 213, 202);
}


input:focus, textarea {
    outline: none !important; /*remove ugly box around active input box*/
}

.user-info-input-form-container{
    max-width: 500px;
    width: 100%;
    min-width: 340px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
}

.test-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: antiquewhite;
    border: 1px solid black;
    margin: 20px;
    border-radius: 20px;
    padding: 40px;
}

.bazaar-confirm-container{
    color: white;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100%;
    gap: 10px;
    padding-right: 5px;
    overflow-y: scroll;
    border: 1px dotted grey;
    border-radius: 5px;
    padding: 5px;
}

.confirm-span{
    flex:1;
    width: 100%;
    border-bottom-color: rgb(110, 110, 110);;
    border-bottom-width: 2px;
    border-bottom-style: dotted;
    height: 1px;
  }

.confirm-line{
    width: 100%;
    height: 1px;
    border-bottom-color: rgb(198, 198, 198);
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.confirm-item-name{
}

.confirm-item-amount{

}

.confirm-items-container{

}

.confirm-address{
    font-size: 15px;
    margin-top: 10px;
}