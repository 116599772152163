.xxbig-button {
    margin: 30px;
    font-size: 60px;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    padding: 15px;
    border-width:5px;
}

.big-button {
    margin: 20px;
    font-size: 40px;
    border-radius: 10px;
    border-style: solid;
    border-color: black;
    padding: 10px;
    border-width:4px;
}

.medium-button {
    margin: 15px;
    font-size: 30px;
    border-radius: 12px;
    border-style: solid;
    border-color: black;
    padding: 15px;
    border-width:3px;
}

.small-button {
    margin: 5px;
    font-size: 12px;
    border-radius: 8px;
    border-style: solid;
    border-color: black;
    padding: 5px;
    border-width: 2px;
}

.big-width {
    min-width:300px
}

.medium-width {
    min-width:225px
}

.short-width{
    min-width:150px
}

.green {
    color:white;
    background-color: chartreuse;
}

.dgreen {
    color:white;
    background-color: rgb(36, 72, 0);
}

.lgreen {
    color:rgb(0, 0, 0);
    background-color: rgb(183, 250, 167);
}

.blue {
    color:white;
    background-color: rgb(0, 78, 156);
}

.dblue {
    color:white;
    background-color: rgb(0, 31, 62);
}

.lblue {
    color:rgb(0, 0, 0);
    background-color: rgb(0, 217, 255)
}

.yellow {
    color:white;
    background-color: rgb(251, 255, 5);
}

.dyellow {
    color:white;
    background-color: rgb(125, 136, 0);
}

.lyellow {
    color:rgb(0, 0, 0);
    background-color: rgb(236, 242, 171);
}

.red {
    color:white;
    background-color: rgb(201, 0, 0);
}

.dred {
    color:white;
    background-color: rgb(78, 0, 0);
}

.lred {
    color:rgb(0, 0, 0);
    background-color: rgb(255, 137, 137);
}

.purple {
    color:white;
    background-color: rgb(174, 17, 199);
}

.dpurple {
    color:white;
    background-color: rgb(80, 0, 92);
}

.lpurple {
    color:rgb(0, 0, 0);
    background-color: rgb(243, 166, 255);
}

.pink {
    color:white;
    background-color: rgb(255, 0, 123);
}

.dpink {
    color:white;
    background-color: rgb(124, 2, 61);
}

.lpink {
    color:rgb(0, 0, 0);
    background-color: rgb(255, 158, 205);
}

.mcolour {
    color:rgb(255, 255, 255);
    background-color: #180432
}

.padded-big {
    padding-bottom:100px;
}

.boomroom-logo {
    width: 1000px;
}

.br-subtitle{
    color:#f1f1f1;
    font-size: 50px;
    font-family: nowbold;
    text-align: center;
    margin-top: 20px;
}

.br-subsubtitle{
    color:#f1f1f1;
    font-size: 30px;
    font-family: nowbold;
    text-align: center;
    margin-top: 10px;
}

.center-everything{
    display: flex;
    justify-content: center;
    align-items: center;
}



.br-htp{
    width: 500px;
}

.br-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.br-menu-button{
  width:170px;
  height: 100px;
  text-align: center;
  margin: 20px 14px 50px 14px;
  font-size: 22px;
}

.br-page-title{
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 80px;
}

.br-mintrow{
  width: 1260px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
}

.br-mintimage{
  width: 600px;
}

.br-mintdescription{
  text-align: start;
  margin-left: 30px;
  color: azure;
  font-family: nowbold;
  font-size: 26px;
  width: 630px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nowbold {
    font-family: nowbold;
}

:root {
    --clr-neon: hsl(317, 100%, 54%);
    --clr-neon-blue:hsl(210, 100%, 50%);
    --clr-neon-lblue:hsl(181, 96%, 73%);
    --clr-neon-red:rgb(201, 0, 0);
    --clr-neon-gray:rgb(208, 208, 208);
    --clr-neon-yellow:rgb(251, 255, 5);
    --clr-neon-orange:rgb(251, 159, 0);
    --clr-neon-green:chartreuse;
    --clr-neon-dgreen:rgb(36, 72, 0);
    --clr-neon-grey:rgb(50, 50, 50);
    --clr-neon-brown:rgb(138, 92, 22);
    --clr-neon-off:rgb(40, 40, 40);
    --clr-bg: hsl(323 21% 16%);

    --scale: 1;
  }
  
  .neon-button {
    font-family: nowbold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    padding: 0.2em 0.8em;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    position: relative;
  }

  .neon-button-off {
    font-family: nowbold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0.2em 0.8em;
    border-radius: 0.25em;
    position: relative;
    color: var(--clr-neon-off);
    border: var(--clr-neon-off) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-off), 0 0 0.5em 0 var(--clr-neon-off);
  }

  .neon-button.neon-pink {
    color: var(--clr-neon);
    border: var(--clr-neon) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
  }

  .neon-button.neon-gray {
    color: var(--clr-neon-gray);
    border: var(--clr-neon-gray) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-gray), 0 0 0.5em 0 var(--clr-neon-gray);
  }

  .neon-button.neon-brown {
    color: var(--clr-neon-brown);
    border: var(--clr-neon-brown) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-brown), 0 0 0.5em 0 var(--clr-neon-brown);
  }

  .neon-button.neon-orange {
    color: var(--clr-neon-orange);
    border: var(--clr-neon-orange) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-orange), 0 0 0.5em 0 var(--clr-neon-orange);
  }

  .neon-button.neon-blue {
    color: var(--clr-neon-blue);
    border: var(--clr-neon-blue) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-blue), 0 0 0.5em 0 var(--clr-neon-blue);
  }

  .neon-button.neon-lblue {
    color: var(--clr-neon-lblue);
    border: var(--clr-neon-lblue) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-lblue), 0 0 0.5em 0 var(--clr-neon-lblue);
  }

  .neon-button.neon-red {
    color: var(--clr-neon-red);
    border: var(--clr-neon-red) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-red), 0 0 0.5em 0 var(--clr-neon-red);
  }

  .neon-button.neon-green {
    color: var(--clr-neon-green);
    border: var(--clr-neon-green) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-green), 0 0 0.5em 0 var(--clr-neon-green);
  }

  .neon-button.neon-yellow {
    color: var(--clr-neon-yellow);
    border: var(--clr-neon-yellow) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-yellow), 0 0 0.5em 0 var(--clr-neon-yellow);
  }
  
  .neon-button::before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(5px) rotateX(5deg) scale(0.8, 0.2);
    filter: blur(20px);
    opacity: 0.2;
  }

  .neon-button.active::before {
    opacity: 1;
  }

  .neon-button.neon-pink::before{
    background: var(--clr-neon);
  }

  .neon-button.neon-brown::before{
    background: var(--clr-neon-brown);
  }

  .neon-button.neon-blue::before{
    background: var(--clr-neon-blue);
  }

  .neon-button.neon-orange::before{
    background: var(--clr-neon-orange);
  }

  .neon-button.neon-gray::before{
    background: var(--clr-neon-gray);
  }

  .neon-button.neon-lblue::before{
    background: var(--clr-neon-lblue);
  }

  .neon-button.neon-red::before{
    background: var(--clr-neon-red);
  }

  .neon-button.neon-yellow::before{
    background: var(--clr-neon-yellow);
  }

  .neon-button.neon-green::before{
    background: var(--clr-neon-green);
  }
  
  .neon-button::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 100ms linear;
  }

  .neon-button.neon-pink::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon);
    background-color: var(--clr-neon);
  }

  .neon-button.neon-blue::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-blue);
    background-color: var(--clr-neon-blue);
  }

  .neon-button.neon-brown::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-brown);
    background-color: var(--clr-neon-brown);
  }

  .neon-button.neon-orange::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-orange);
    background-color: var(--clr-neon-orange);
  }

  .neon-button.neon-gray::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-gray);
    background-color: var(--clr-neon-gray);
  }

  .neon-button.neon-lblue::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-lblue);
    background-color: var(--clr-neon-lblue);
  }

  .neon-button.neon-red::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-red);
    background-color: var(--clr-neon-red);
  }

  .neon-button.neon-yellow::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-yellow);
    background-color: var(--clr-neon-yellow);
  }

  .neon-button.neon-green::after {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-green);
    background-color: var(--clr-neon-green);
  }
  
  .neon-button:hover,
  .neon-button:focus {
    color: var(--clr-bg);
    text-shadow: none;
  }
  
  .neon-button:hover::before,
  .neon-button:focus::before {
    opacity: 1;
  }
  .neon-button:hover::after,
  .neon-button:focus::after {
    opacity: 1;
  }

  .neon-button:hover::after,
  .neon-button:focus::after {
    opacity: 1;
  }

  .neon-button:hover::before,
  .neon-button:focus::before {
    opacity: 1;
  }

  .neon-button.neon-pink.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon);
    background-color: var(--clr-neon);
    color:black;
  }

  .neon-button.neon-blue.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-blue);
    background-color: var(--clr-neon-blue);
    color:black;
  }

  .neon-button.neon-lblue.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-lblue);
    background-color: var(--clr-neon-lblue);
    color:black;
  }

  .neon-button.neon-yellow.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-yellow);
    background-color: var(--clr-neon-yellow);
    color:black;
  }

  .neon-button.neon-gray.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-gray);
    background-color: var(--clr-neon-gray);
    color:black;
  }

  .neon-button.neon-brown.active {
    box-shadow: 0 0 2em 0.5em var(--clr-neon-brown);
    background-color: var(--clr-neon-brown);
    color:black;
  }

  .neon-line {
    height: 4px;
    max-width: 1260px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 95%;
  }

  .neon-line.neon-pink {
    background-color: var(--clr-neon);
    color: var(--clr-neon);
    border: var(--clr-neon) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
  }

  .neon-line.neon-red {
    background-color: var(--clr-neon-red);
    color: var(--clr-neon-red);
    border: var(--clr-neon-red) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-red), 0 0 0.5em 0 var(--clr-neon-red);
  }

  .neon-line.neon-orange {
    background-color: var(--clr-neon-orange);
    color: var(--clr-neon-orange);
    border: var(--clr-neon-orange) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-orange), 0 0 0.5em 0 var(--clr-neon-orange);
  }

  .neon-line.neon-gray {
    background-color: var(--clr-neon-gray);
    color: var(--clr-neon-gray);
    border: var(--clr-neon-gray) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-gray), 0 0 0.5em 0 var(--clr-neon-gray);
  }

  .neon-line.neon-blue {
    background-color: var(--clr-neon-blue);
    color: var(--clr-neon-blue);
    border: var(--clr-neon-blue) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-blue), 0 0 0.5em 0 var(--clr-neon-blue);
  }

  .neon-line.neon-lblue {
    background-color: var(--clr-neon-lblue);
    color: var(--clr-neon-lblue);
    border: var(--clr-neon-lblue) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-lblue), 0 0 0.5em 0 var(--clr-neon-lblue);
  }

  .neon-line.neon-yellow {
    background-color: var(--clr-neon-yellow);
    color: var(--clr-neon-yellow);
    border: var(--clr-neon-yellow) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-yellow), 0 0 0.5em 0 var(--clr-neon-yellow);
  }

  .neon-line.neon-green {
    background-color: var(--clr-neon-green);
    color: var(--clr-neon-green);
    border: var(--clr-neon-green) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-green), 0 0 0.5em 0 var(--clr-neon-green);
  }

  .neon-line.neon-brown {
    background-color: var(--clr-neon-brown);
    color: var(--clr-neon-brown);
    border: var(--clr-neon-brown) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-brown), 0 0 0.5em 0 var(--clr-neon-brown);
  }

  .neon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    /* padding: 0.25em 1em;
    border-radius: 0.25em; */
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    position: relative;
  }

  .neon-text.neon-red {
    color: var(--clr-neon-red);
  }

  .neon-text.neon-brown {
    color: var(--clr-neon-brown);
  }

  .neon-text.neon-blue {
    color: var(--clr-neon-blue);
  }

  .neon-text.neon-orange {
    color: var(--clr-neon-orange);
  }

  .neon-text.neon-gray {
    color: var(--clr-neon-gray);
  }

  .neon-text.neon-lblue {
    color: var(--clr-neon-lblue);
  }

  .neon-text.neon-pink {
    color: var(--clr-neon);
  }

  .neon-text.neon-yellow {
    color: var(--clr-neon-yellow);
  }

  .neon-text.neon-green {
    color: var(--clr-neon-green);
  }

  .neon-text.neon-dgreen {
    color: var(--clr-neon-dgreen);
  }

  .font-small{
    font-size: 20px;
  }

  .font-medium{
    font-size: 30px;
  }

  .font-large{
    font-size: 60px;
  }

  .neon-border-red {
    border: var(--clr-neon-red) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-red), 0 0 10px 0 var(--clr-neon-red);
  }

  .neon-border-blue {
    border: var(--clr-neon-blue) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-blue), 0 0 10px 0 var(--clr-neon-blue);
  }

  .neon-border-orange {
    border: var(--clr-neon-orange) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-orange), 0 0 10px 0 var(--clr-neon-orange);
  }

  .neon-border-gray {
    border: var(--clr-neon-gray) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-gray), 0 0 10px 0 var(--clr-neon-gray);
  }

  .neon-border-lblue {
    border: var(--clr-neon-lblue) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-lblue), 0 0 10px 0 var(--clr-neon-lblue);
  }

  .neon-border-pink {
    border: var(--clr-neon) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon), 0 0 10px 0 var(--clr-neon);
  }

  .neon-border-yellow {
    border: var(--clr-neon-yellow) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-yellow), 0 0 10px 0 var(--clr-neon-yellow);
  }

  .neon-border-green {
    border: var(--clr-neon-green) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-green), 0 0 10px 0 var(--clr-neon-green);
  }

  .neon-border-grey {
    border: var(--clr-neon-grey) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-grey), 0 0 10px 0 var(--clr-neon-grey);
  }

  .neon-border-brown {
    border: var(--clr-neon-brown) 0.125em solid;
    box-shadow: inset 0 0 5px 0 var(--clr-neon-brown), 0 0 10px 0 var(--clr-neon-brown);
  }

  .neon-button-simple {
    font-family: nowbold;
    font-size: 25px;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    padding: 0.25em 1em;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    position: relative;
  }

  .neon-button-simple.neon-pink {
    color: var(--clr-neon);
    border: var(--clr-neon) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
  }
  .neon-button-simple.neon-pink.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon);
    background-color: var(--clr-neon);
    color:black
  }

  .neon-button-simple.neon-lblue {
    color: var(--clr-neon-lblue);
    border: var(--clr-neon-lblue) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-lblue), 0 0 0.5em 0 var(--clr-neon-lblue);
  }

  .neon-button-simple.neon-lblue.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-lblue);
    background-color: var(--clr-neon-lblue);
    color:black
  }

  .neon-button-simple.neon-orange {
    color: var(--clr-neon-orange);
    border: var(--clr-neon-orange) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-orange), 0 0 0.5em 0 var(--clr-neon-orange);
  }

  .neon-button-simple.neon-orange.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-orange);
    background-color: var(--clr-neon-orange);
    color:black
  }

  .neon-button-simple.neon-gray {
    color: var(--clr-neon-gray);
    border: var(--clr-neon-gray) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-gray), 0 0 0.5em 0 var(--clr-neon-gray);
  }

  .neon-button-simple.neon-gray.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-gray);
    background-color: var(--clr-neon-gray);
    color:black
  }

  .neon-button-simple.neon-green {
    color: var(--clr-neon-green);
    border: var(--clr-neon-green) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-green), 0 0 0.5em 0 var(--clr-neon-green);
  }

  .neon-button-simple.neon-dgreen {
    color: var(--clr-neon-dgreen);
    border: var(--clr-neon-dgreen) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-dgreen), 0 0 0.5em 0 var(--clr-neon-dgreen);
  }
  .neon-button-simple.neon-green.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-green);
    background-color: var(--clr-neon-green);
    color:black
  }

  .neon-button-simple.neon-red {
    color: var(--clr-neon-red);
    border: var(--clr-neon-red) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-red), 0 0 0.5em 0 var(--clr-neon-red);
  }

  .neon-button-simple.neon-yellow {
    color: var(--clr-neon-yellow);
    border: var(--clr-neon-yellow) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-yellow), 0 0 0.5em 0 var(--clr-neon-yellow);
  }
  .neon-button-simple.neon-yellow.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-yellow);
    background-color: var(--clr-neon-yellow);
    color:black
  }

  .neon-button-simple.neon-brown {
    color: var(--clr-neon-brown);
    border: var(--clr-neon-brown) 0.125em solid;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon-brown), 0 0 0.5em 0 var(--clr-neon-brown);
  }
  .neon-button-simple.neon-brown.active {
    box-shadow: 0 0 1em 0.2em var(--clr-neon-brown);
    background-color: var(--clr-neon-brown);
    color:black
  }

  .neon-hover-green:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-green);
    background-color: var(--clr-neon-green);
    color:black
  }

  .neon-hover-red:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-red);
    background-color: var(--clr-neon-red);
    color:black
  }

  .neon-hover-yellow:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-yellow);
    background-color: var(--clr-neon-yellow);
    color:black
  }

  .neon-hover-orange:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-orange);
    background-color: var(--clr-neon-orange);
    color:black
  }

  .neon-hover-pink:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon);
    background-color: var(--clr-neon);
    color:black
  }

  .neon-hover-gray:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-gray);
    background-color: var(--clr-neon-gray);
    color:black
  }

  .neon-hover-brown:hover{
    box-shadow: 0 0 2em 0.5em var(--clr-neon-brown);
    background-color: var(--clr-neon-brown);
    color:black
  }

  .br-componentbuilder-sortrow{
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 1280px;
  }
  
  .br-componentcard-image{
    width: 170px;
    height: 170px;
    border-radius: 10px 10px 0px 0px;
  }

  .br-componentcard {
    width: 170px;
    margin: 5px;
    border-radius: 10px 10px 10px 10px;
    background-color: rgb(0, 0, 0);
  }

  .br-textmid{
    text-align: center;
  }
  

  .br-componentcard-bottombox{
    justify-content: center;
    display: flex;
    min-height: 100px;
    padding: 5px;
    background-color: rgb(0, 0, 0);
    /* border-radius: 0px 0px 10px 10px; */
    color: rgb(255, 255, 255);
    font-family: nowbold;
    border-radius: 0px 0px 10px 10px;
  }

  .br-traitlist{
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 100px;
    padding: 10px;
    border-radius: 20px;
    margin: 5px;
  }

  .br-blackline{
    width:100%;
    height: 4px;
    background-color: black;
  }

  .br-blackline-half{
    width:50%;
    height: 2px;
    background-color: black;
  }

  .br-componentextract-image {
    width: 500px;
    margin: 30px;
  }

  .br-componentupgrade-image {
    width: 500px;
    height: 500px;
  }

  .br-upgradecomponenet-square{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1275px;
  }

  .br-upgradecomponenet-square-name{
    /* position:absolute; */
    width:100%;
    color:#f1f1f1;
    font-size: 40px;
    font-family: nowbold;
    text-align: center;
    height: 100px;
  }

  .br-upgradecomponenet-square-name.left{
    left: 0px;
  }

  .br-upgradecomponenet-square-name.right{
    right: 0px;
  }

  .br-upgradecomponenet-square-image{
    /* position:absolute; */
    width: 500px;
    height: 500px;
  }

  .br-upgradecomponenet-square-image.left{
    left: -30px;
  }

  .br-upgradecomponenet-square-image.right{
    right: 30px;
  }

  .br-componentupgrade-image {
    width: 100%;
    background-color: rgb(0, 0, 0);
  }

  .br-upgradecomponenet-square-info{
    font-family: nowbold;
    width: 500px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 30px;
  }
  .br-upgradecomponenet-square-info.left{
    left: 0px;
  }
  .br-upgradecomponenet-square-info.right{
    right: 0px;
  }

  .br-upgradecomponenet-square-cancel{
    font-family: nowbold;
  }

  .br-upgradecomponenet-square-upgrade{
    font-family: nowbold;
  }

  .br-upgradecomponenet-square-traits{
    width: 100%;
  }

  .br-upgradecomponenet-square-traits.left{
    left: 0px;
  }

  .br-upgradecomponenet-square-traits.right{
    right: 0px;
  }

  .br-upgradecomponenet-square-arrow{
    font-size: 100px;
    font-family: nowbold;
    margin-top: -200px;
  }

  .br-roomconstruction-sortrow{
    max-width: 1280px;
  }

  .br-boomroomcard-image{
    height: 340px;
    width: 340px;
    border-radius: 10px 10px 0px 0px;
    background-color: black;
  }

  .br-boomroomcard {
    margin: 20px;
    border-radius: 10px 10px 10px 10px;
  }

  .br-boomroomcard-bottombox{
    justify-content: center;
    display: flex;
    min-height: 100px;
    width: 340px;
    /* background-color: rgb(255, 255, 255); */
    background-color: rgb(0, 0, 0);
    /* border-radius: 0px 0px 10px 10px; */
    color: rgb(255, 255, 255);
    font-family: nowbold;
    font-size: 20px;
    border-radius: 0px 0px 10px 10px;
    padding:10px;
  }

  .br-blackline{
    width:100%;
    height: 4px;
    background-color: black;
  }

  .flex1{
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .bg-neon-yellow{
    background-color: var(--clr-neon-yellow);
  }

  .bg-neon-pink{
    background-color: var(--clr-neon-pink);
  }

  .bg-neon-red{
    background-color: var(--clr-neon-red);
  }

  .bg-neon-blue{
    background-color: var(--clr-neon-blue);
  }

  .bg-neon-green{
    background-color: var(--clr-neon-green);
  }

  .bg-black{
    background-color: black;
  }

  .width-100{
    width:100%;
  }

  .width-250{
    width:250px;
  }

  .margin-3{
    margin:3px;
  }

  .margin-5{
    margin:5px;
  }

  .margin-10{
    margin:10px;
  }

  .margin-15{
    margin:15px;
  }

  .margin-20{
    margin:20px;
  }

  .margin-50{
    margin:50px;
  }

  .margin-top-20{
    margin-top: 20px;
  }

  .br-boomroomcard-bl-br{
    border-bottom-left-radius: 10px;
  }

  .br-boomroomcard-br-br{
    border-bottom-right-radius: 10px;
  }

  .font-35{
    font-size: 35px;
  }

  .font-30{
    font-size: 30px;
  }

  .font-20{
    font-size: 20px;
  }

  .font-25{
    font-size: 25px;
  }

  .font-15{
    font-size: 15px;
  }
  .font-10{
    font-size: 10px;
  }

  .height-50{
    height: 50px;
  }

  .br-roomconstruct-container{
    justify-content: space-around;
    display: flex;
    width: 1280px;
  }

  /* .br-roomconstruct-container-left{
    width:500px;
  }

  .br-roomconstruct-container-right{
    width:780px;
  } */

  .br-roomconstruct-image {
    width: 650px;
    height: 650px;
  }

  .flex{
    display: flex;
  }

  .justify-center{
    justify-content: center;
  }

  .justify-around{
    justify-content: space-around;
  }

  .justify-between{
    justify-content: space-between;
  }

  .align-center{
    align-items: center;
  }

  .br-roomconstruct-componentcard-container{
    width: 300px;
    border-radius: 10px;
    margin: 10px 20px 40px 20px;
  }

  .br-roomconstruct-componentcard-image{
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background-color: black;
  }

  .br-roomconstruct-componentcard-empty{
    width: 100%;
    height: 300px;
    border-radius: 10px 10px 0px 0px;
  }

  .br-roomconstruct-componentcard-bottombox{
    min-height: 60px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: nowbold;
    font-size: 18px;
  }

  .br-roomconstruct-componentcard-buttoncontainer{
    min-height: 70px;
    background-color: black;
    border-radius: 0px 0px 10px 10px;
  }

  .br-roomconstruct-stats{
    width: 630px;
    height: 800px;
  }

  .br-roomconstruct-component-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    color:black;
    height: 85px;
    padding: 3px;
  }

  .br-roomconstruct-component-box.filled{
    justify-content: space-between;
  }

  .br-roomconstruct-component-box-ct{
    font-size: 15px;
    color: rgb(172, 252, 255);
  }

  .br-roomconstruct-component-box-cn{
    font-size: 15px;
    color: rgb(231, 231, 231);
  }

  .br-roomconstruct-component-box-cs{
    font-size: 19px;
    color: rgb(32, 113, 11);
  }

  .br-roomconstruct-component-box-e{
    font-size: 14px;
    color: rgb(112, 13, 13);
    margin-top: 15px;
  }

  .br-roomconstruct-cosmetic-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    color:black;
    min-height: 100px;
    padding: 3px;
    text-align: center;
  }

  .br-roomconstruct-cosmetic-box.filled{
    justify-content: space-between;
  }

  .br-roomconstruct-cosmetic-box-ct{
    font-size: 15px;
    color: rgb(172, 252, 255);
  }

  .br-roomconstruct-cosmetic-box-cn{
    font-size: 15px;
    color: rgb(231, 231, 231);
  }

  .br-roomconstruct-cosmetic-box-cs{
    font-size: 19px;
    color: rgb(32, 113, 11);
  }

  .br-roomconstruct-cosmetic-box-e{
    font-size: 14px;
    color: rgb(112, 13, 13);
    margin-top: 15px;
  }

  .br-roomdecorator-roomimage{
    width: 750px;
    height: 750px;
    position: relative;
  }

  .br-roomdecorator-componentlist-container{
    width: 150px;
    margin: 8px;
    background-color: black;
    font-family: nowbold;
    font-size: 10px;
    border-radius: 10px;
  }

  .br-roomdecorator-componentlist-image{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .br-roomdecorator-componentlist-container-bottombox{
    padding: 5px;
    width: 100%;
    font-size: 10px;
  }

  .br-roomdecorator-roomcomponents{
    width: 650px;
    margin: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position-relative {
    position: relative;
  }

  .br-roomdecorator-componentlist-container-bottombox-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .br-howtoplay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .br-howtoplay-row{
    margin:6px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .br-construction-select-buttons{
    width:150px;
  }

  .br-compbuilder-select-buttons{
    width:200px;
  }

  .br-construction-complist{
    margin-top: 20px;
  }

  .br-draggable-image{
    position: absolute;
    cursor: move;
    color: rgb(255, 255, 255);
    margin: auto;
    user-select: none;
    z-index: 10;
  }
  .br-draggable-button{
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: black;
    height: 50px;
    width: 50px;
    z-index: 11;
    left: 50%;
    top: 50%;
    border-radius: 10px;
  }

  .br-draggable-button.plus{
    background-color: green;
  }

  .br-draggable-button.minus{
    background-color: red;
  }

  .br-draggable-image.fixed{
    height: 750px;
    width: 750px;
  }

  /* .br-draggable-image.notFixed{
    height: calc(250px * var(--scale));
    width: calc(250px * var(--scale));
  } */

  .br-construction-spinner-box{
    min-height: 500px;
  }
  .br-componentbuilder-spinner-box{
    min-height: 295px;
  }

  .br-boomroom-scaling{
    height: 50px;
    justify-content: space-around;
    width: 80%;
    font-size: 25px;
  }

  .scaling-button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border-radius: 10px;
    border-style: solid;
    height: 35px;
    width: 35px;
    border-width: 2px;
    margin-top: 3px;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 100;
  }

.scaling-button.green{
  background-color: rgb(12, 115, 12);
}

.scaling-button.red{
  background-color: rgb(172, 16, 16);
}

.move-forward{
  z-index: 100;
}

.br-stakingpool{
  display:flex;
  align-items: center;
  flex-direction: column;
  width: 340px;
  border-radius: 40px;
  background-color: rgb(0, 0, 0);
  margin: 20px;
  font-family: nowbold;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding-bottom: 23px;
}

.br-pool-image{
  width: 340px;
  height: 340px;
  border-radius: 40px 40px 0px 0px;
}

.br-pool-section{
  /* justify-content: space-between; */
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  align-items: baseline;
}

.span{
  flex: 1;
  width: 100%;
  border-bottom-color: rgb(255, 255, 255);;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.br-pool-title{
  font-size: 22px;
  margin: 15px;
}

.br-upgradecomponenet-square-side{
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.br-upgradecomp-trait-type{
  color: rgb(3, 175, 141);
  font-size: 20px;
  font-family: nowbold;
}

.br-upgradecomp-trait-value{
  color: rgb(181, 229, 255);
  font-size: 15px;
  font-family: nowbold;
}

.br-upgradecomp-trait-score{
  color: rgb(57, 193, 3);
  font-size: 20px;
  font-family: nowbold;
}

.br-scoreboard-row{
  /* justify-content: space-between; */
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
}

.br-scoreboard{
  font-size: 18px;
  max-width: 350px;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-family: nowbold;
  padding: 20px;
  background-color: black;
  justify-content: space-between;
  height: 660px;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.border-radius-30{
  border-radius: 30px;
}

