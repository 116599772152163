.raffles-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin: 160px 0px 60px 0px;
}

/*edit raffles-main for screen size under 1200*/
@media (max-width: 1200px){
    .raffles-main{
        margin: 60px 0px 60px 0px;
    }
}