.news-container{
    display: flex;
    min-height: 1000px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    margin: 40px 0px;
}

.news-section{
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    color: white;
    font-family: nowbold;
}

.news-section.navigation{
    align-items: end;
    flex: 1;
    border-right: 1px solid beige;
    gap: 20px;
    text-align: end;
}

.news-section.tweets{
    justify-content: start;
    flex: 1;
    border-left: 1px solid beige;
}

.news-section.article{
    display: flex;
    flex: 1.5;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.news-nav-title{
    font-size: 18px;
    color: rgb(225, 225, 225)
}

.news-nav-container{
    cursor: pointer;
}

.news-nav-date{
    font-size: 12px;
    color: rgb(136, 136, 136);
    font-style: italic;
}

.news-article-title{
    font-size: 30px;
}

.news-article-date{
    font-size: 15px;
    color: rgb(160, 160, 160);
    font-style: italic; 
}

.news-sub-button{
    border-radius: 5px;
    background-color: rgb(186, 90, 0);
    border: 1px solid white;
    color:white;
    width: 90px;
    height: 27px;
}

.news-sub-button:hover{
    background-color: rgba(186, 90, 0, 0.771);
    cursor: pointer;
}