.m-bazaar-item{
    height: var(--height-four);
    outline: var(--border-one) solid #051221;
    background-color: rgb(240, 254, 255);
    border-radius: 3px;
    display: flex;
    color: black;
    max-width: 100%;
    margin: 1px 0px;
}

.m-bazaar-item-image{
    max-width: var(--width-three);
    max-height: var(--height-four);
}

.m-bazaar-item-image-container{
    width: var(--width-three);
    height: var(--height-four);
    background-color: rgb(236, 236, 236);
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-bazaar-item-info-container{
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.m-bazaar-item-info-name{
    width: 100%;
    overflow-wrap: break-word;
    color:rgb(0, 0, 0);
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
}

.m-bazaar-item-info-name:hover{
    color:rgb(36, 36, 255);
}

.m-bazaar-item-info-stock{
    color:rgb(171, 0, 0);
    font-size: 11px;
}

.m-bazaar-item-info-price{
    color:rgb(0, 0, 0);
    font-size: 15px;
}

.m-bazaar-item-info-brand{
    color:rgb(0, 139, 137);
    font-size: 10px;  
}

.m-bazaar-item-info-wl{
    color:rgb(171, 0, 0);
    font-size: 10px;   
}

.m-sort-option{
    background-color: rgba(128, 128, 128, 0.5);
    border: 1px solid grey;
    padding: 2px 5px;
    border-radius: var(--border-radius-one);
    font-size: 15px;
}

.m-show-filters-button{
    background-color: var(--background-three);
    padding: 3px 15px;
    border-radius: var(--border-radius-one);
    border: 1px solid black;
    font-size: 15px;
}

.m-sort-dropdown {
    position: relative;
    display: inline-block;
    color: white;
    font-family: nowbold;
    font-size: 15px;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: var(--border-radius-one);
    width: 140px;
    border: 1px solid black;
    background-color: rgb(189, 94, 6);
}

.m-sort-dropdown:hover .sort-dropdown-content {
    display: block;
  }

.m-sort-dropdown-item:active .sort-dropdown-content {
    display: none;
}

.m-bazaar-item-info-type{
    color:rgb(97, 97, 97);
    font-size: 10px;
    font-style: italic;
}

.mobile-filter-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 360px;
    background-color: #051221;
    position: absolute;
    top: 0px;
    transition: all 0.3s ease;
    padding: 5px;

}

.m-bazaar-filter-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /* margin-top: calc(0px - var(--margin-one)); */
    background-color: var(--background-two);
    border-radius: var(--border-radius-one);
}

.m-bazaar-filters{
    display: flex;
    flex-direction: column;
    width: 360px;
    z-index: 10;
    gap:10px;
    overflow: scroll;
    padding: 10px 5px;
}

.mobile-filter-container-x{
    margin-right: 20px;
    font-size: 40px;
    padding: 5px;
    color: rgb(136, 0, 0);
    cursor: pointer;

}

.mobile-filter-container-x:hover{
    color: rgb(136, 0, 0, 0.8);
}

.mobile-filter-container-x:active{
    transform: scale(1.2)
}

.mobile-bazaar-top{
    background-color: var(--background-one);
    border-bottom-right-radius: var(--borderRadius-one);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    z-index: 10;
    gap:10px;
    box-shadow: inset 0px 10px 20px rgba(0,0,0,0.9);
    color: white;
    padding: 10px;
}

.mobile-bazaar-item-focus-section-image{
    width: 350px;
    height: max-content;
}

.mobile-bazaar-item-focus-container{
    width:360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    box-shadow: inset 0px 10px 20px rgba(0,0,0,0.9);
    padding-top: 50px;
    padding-bottom: 50px;
}

.mobile-steps-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 360px;
    width: 1200px;
    max-height: 1200px;
    background-color: 'grey';
    border-radius: '10px';
    border-right: 4px solid rgb(247, 74, 74);
    border-top: 4px solid rgb(140, 255, 111);
    border-left: 4px solid rgb(0, 207, 249);
    border-bottom: 4px solid rgb(240, 0, 245);
    padding: 20px;
    border-radius: 30px;
    background: rgb(250,250,250);
    background: radial-gradient(circle, rgb(255, 255, 255) 87%, rgb(233, 252, 255) 13%);
    gap: 20px;
}

.m-bazaar-inventory-card-image{
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
}

.m-bazaar-inventory-card-image-container{
    display: flex;
    flex-shrink: none;
    flex-grow: none;
    border-radius: 5px;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    background-color: rgb(227, 227, 227);
    justify-content: center;
    align-items: center;
}

.m-bazaar-inventory-card-info-container {
    padding: 4px;
    width: 100%;
}