.bazaar-inventory-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    min-width: 350px;
    width: 100%;
    align-self: center;
    gap: 5px;
}

.bazaar-inventory-card{
    border-radius: 6px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(46, 122, 135);
    width: 100%;
    background-color: aliceblue;
    transition: all 0.1s;
}

.bazaar-inventory-card:hover{
    transform: scale(1.015);
    border: 1px solid rgb(0, 177, 0);
}

.bazaar-inventory-card:active{
    transform: scale(1);
}

.item-inventory-container{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: scroll!important;
}

.bazaar-inventory-card.clicked{
    transform: scale(1.02);
    border: 1px solid rgb(0, 177, 0);
    transition: all 0.1s;
    background-color: rgb(195, 251, 226);
}

.bazaar-inventory-card.clicked:active{
    transform: scale(1);
}

.bazaar-inventory-card-image-container{
    display: flex;
    flex-shrink: none;
    flex-grow: none;
    border-radius: 5px;
    min-height: 100px;
    min-width: 100px;
    max-height: 100px;
    max-width: 100px;
    background-color: rgb(227, 227, 227);
    justify-content: center;
    align-items: center;
}

.bazaar-inventory-card-image{
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
}

.bazaar-inventory-card-info-container {
    padding: 4px;
    width: 100%;
}

.bazaar-inventory-card-info-owned {
    display: flex;
    color: rgb(0, 167, 31);
    width: 100%;
    justify-content: flex-end;
    font-family: nowbold;
}

.steplines-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color:aliceblue;
    background-color: rgb(15, 46, 3);
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    font-family: nowbold;
    font-size: 15px;
}

.steplines-circle.current{
    background-color: rgb(42, 157, 0);
}

.steps-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 1200px;
    width: 1200px;
    height: 800px;
    background-color: 'grey';
    border-radius: '10px';
    border-right: 4px solid rgb(247, 74, 74);
    border-top: 4px solid rgb(140, 255, 111);
    border-left: 4px solid rgb(0, 207, 249);
    border-bottom: 4px solid rgb(240, 0, 245);
    padding: 20px;
    border-radius: 30px;
    background: rgb(250,250,250);
    background: radial-gradient(circle, rgb(255, 255, 255) 87%, rgb(233, 252, 255) 13%);
    gap: 20px;
}

.steps-content-container{
    height: 100%;
    max-width: 1100px;
    width: 100%;
    min-width: 355px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
    border: 1px dashed black;
    border-radius: 10px;
    background-color: #031932;
    padding: 5px;
}

.steps-line-seperator{
    background-color: rgb(15, 46, 3);
    border-radius: 10px;
    width: 100%;
    height: 5px;
}

.steps-line-seperator.current{
    background-color: rgb(42, 157, 0);
}

.steplines-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
}

.steps-button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: nowbold;
    border-radius: 10px;
    width: 100px;
    height: 30px;
    font-size: 15px;
    padding: 5px;
    border: 2px solid rgb(71, 71, 71);
    cursor: pointer;
}

.steps-button.next{
    background-color: rgb(110, 220, 0);
}

.steps-button.next:hover{
    background-color: chartreuse;
}

.steps-button.prev{
    background-color: rgb(220, 154, 0);
}

.steps-button.prev:hover{
    background-color: rgb(255, 196, 0);
}

.steps-button.inactive{
    cursor:default;
    background-color: rgb(147, 147, 147);
}
.steps-button.inactive:hover{
    background-color: rgb(147, 147, 147);
}

.steps-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: row;
}

.pay-shipping-options-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: nowbold;
    padding: 30px;
    text-align: center;
    height: 100%;
}