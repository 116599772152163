.zoomers-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
}

.zoomer-card{
    width: 300px;
    border-radius: 5px;
    overflow: hidden;
}

.zoomer-card-image-container{
    width: 300px;
    height: 300px;
}

.zoomer-card-image{
    width: 100%;
}

.zoomer-card-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: nowbold;
    font-size: 20px;
    /* border: 1px solid rgb(154, 0, 162); */
    border: 3px groove rgb(255, 217, 0);
    background: rgb(69,9,80);
    background: radial-gradient(circle, rgba(69,9,80,1) 0%, rgba(135,7,161,1) 100%);
    padding: 10px;
    color: rgb(255, 255, 255);
}

.ro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0px;
}

.zoomer-top-box{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 200px;
    padding: 20px;
    background-color: rgb(156, 33, 133);
    border-radius: 10px;
    gap: 5px;
    font-family: nowBold;
    font-size: 20px;
    border: 3px groove rgb(255, 217, 0);
    color: rgb(255, 255, 255);
}

.z-span{
    flex: 1;
    width: 100%;
    border-bottom: 2px dashed rgb(0, 0, 0);
}
