/*All of the styles ported over from styles.tsx*/

.provider-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: beige;
  border-radius: 2px;
  width: 310px;
  font-family: nowbold;
  font-size: 20px;
  padding: 5px;
  margin: 10px;
  transition: all .1s ease-in-out;
}

.provider-style:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all .1s ease-in-out;
}

.provider-row {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-modal-title {
  font-size: 35px;
  font-family: Open Sans;
  color: white;
}

.connectModalBody {
  border: 5px solid beige;
  background-color: #051221;
  width: 25vw;
  height: 50vh;
}

.header {
  background-color: #051221;
  height: 9.26vh;
}

.header-logo {
  height: 66px;
  background-color: #fff2cc;
  border-radius: 45px;
  margin-left: -6px;
}

.fixed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  width: 92%;
  max-width: 1920px;
  min-width: 1280px;
  z-index: 1000;
  background-color: rgb(3, 10, 17, 97%);
  align-items: center;
  height: 72px;
  margin-top: 10px;
  border-radius: 50px;
}



.marketplace-card-image {
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -25px;

}

.marketplaceCardModal {
  height: 100%;
  background-color: #051221;
}

.marketplace-card-row {
  color: #303030 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.marketplace-card-line {

  height: 2px;
  background-color: #051221;
  margin-right: 5px;
  margin-left: 5px;
}

.marketplace-card {
  width: 300px !important;
  display: flex !important;
  justify-content: center !important;
  justify-items: center !important;
  border-radius: 10px !important;
  color: #404040 !important;
  background-color: #fff2cc !important;
  border-width: 0px !important;
  font-family: Roboto Mono !important;
  font-weight: 700 !important;
  height: 450px !important;
  margin: 10px !important;
}

.marketplace-card:hover {
  cursor: pointer;
}

.marketplace-trait-row-trait {
  align-Items: center !important;
  justify-content: center !important;
  font-family: nowbold !important;
  color: #759aab !important;
}

.marketplace-trait-row-trait-type {
  align-Items: center !important;
  justify-content: center !important;
  text-align: center;
  font-family: nowbold !important;
  font-size: 15px !important;
  overflow: hidden;
}

.marketplace-trait-row-trait-percentile {
  align-Items: center !important;
  justify-content: center !important;
  font-family: nowbold !important;
  color: #535353;
  text-align: center !important;
  font-size: 13px !important;
  padding-left: 5px;
}

.marketplace-trait-col {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 7px;
  padding: 2px;
  margin: 3px;
  background-color: rgb(221, 221, 221);
  color: #051221;
  font-family: Roboto Mono;
  height: 70px;
  width: 130px;

}

.marketplaceCardModalImage {
  height: auto;
}

.header-connectbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-size: 16px !important;
  background-color: #759AAB !important;
  border-color: #fff2cc !important;
  border-width: 2px !important;
  border-style: solid;
  padding: 5px 15px;
  font-family: nowbold !important;
  border-radius: 30px;
  height: 50px;
}

.container2 {
  position: relative;
  text-align: center;
  color: #051221;
}


.centered {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@font-face {
  font-family: nowbold;
  src: url("https://boomersquad-web-images.s3.amazonaws.com/public/now.bold-webfont.woff")
}

text {
  white-space: pre-wrap;
  font-size: 14px;
  font-family: CSGordanRegular;
}

.sloganPic {
  width: 100%
}

#hidden {
  font-size: .5px;
  color: #cecece;
  margin-bottom: 1vw;
}

#button {
  border: solid 1px;
  width: 25px;
  height: 23px;
  float: right;
  cursor: pointer;
}

#box {
  height: 250px;
  background: #DFDFDF;
}

/*removing horizontal scroll*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}


#app,
#app>div {
  height: 100%;
  background-color: #051221 !important;
}

.website-body {
  background: #051221 !important;
  /* display: flex; */
  height: 100% !important;
  width: 100% !important;
}


.centered-x-and-y {
  display: flex;
  align-items: center;
  justify-content: center;
}

.please-connect-wallet-image {
  margin: 50px 0px;
  width: 800px;
}

.please-connect-wallet-image2 {
  width: 600px;
}

.no-boomers-found-image {
  margin: 100px 0px;
  width: 800px;
}

.story_img {

  width: 100%;
  object-fit: fill;
  border-style: solid;
  border-width: 3px;
  border-color: #051221;

}

.story-image-row {
  display: flex !important;

  margin-top: 15px;

  justify-content: space-between !important;
  justify-items: space-between !important;
  gap: 6px;
}

.story-image-col {

  width: 1160px;
}

.story-row {
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 5px;
  width: 1160px;
}

.zoomer-story {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 80px;
  font-family: nowbold;
  /* color: #f1f1f1;
     */
  color: #fff2cc;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  width: 1280px;
  position: relative;
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */

}

.title-sub {
  font-size: 40px;
  font-family: nowbold;
  color: #fff2cc;
  text-align: center;
  text-decoration: underline;
}

.onepager-footer {
  background-color: #01060c;
  color: white;
  gap: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.horizontal-teal-line-footer {

  background-color: #70abaf;
  height: 5px;
  width: 100%;

}

.meet-member-artist-link {
  color: #ffffff !important;
}

.white-text {
  color: #f4f4f4;
}

.nowbold {
  font-family: nowbold;
}

.team-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 63vw;
}

.team-row-name {
  font-size: 18px;
  justify-content: center;
  align-items: center;
  width: 190px;
}

.team-row-title {
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.team-image {
  height: 125px;
  width: 125px;
  border-radius: 100px;
}

.faq-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 8px 0px;
  border-radius: 10px;
  max-width: 63vw;
}

.faq-row-question {
  font-size: 27px;
  text-decoration: underline;

}

.faq-row-answer {
  font-size: 20px;
}

.faq-image-one {
  position: absolute;
  height: 330px;
  left: 80%;
  bottom: 82%;
  z-index: 1;
}

.footer-socials-logo {
  height: 50px;
  margin: 0px 40px;
  transition: all .1s ease-in-out;

}

.footer-socials-logo:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all .1s ease-in-out;

}

.footer-legal {
  justify-content: center;
  text-align: center;
  width: 1180px;
  margin-bottom: 20px;
}

.footer-navbar-extras-link {
  font-size: 25px;
  margin: 0px 20px;
  color: #70abaf;
}

.footer-navbar-extras-link:hover {
  transform: scale(1.1);
  transition: all .1s ease-in-out;
}



.story_img.none {
  display: none;
}

.loading-container {
  font-size: 80px;
  justify-content: center;
  align-items: center;
  height: 100px;
  display: flex;
}

.loading-container.none {
  font-size: 0px;
  height: 0px;
}


/*SPINNER*/

.sk-fading-circle {
  margin: 0px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle.none {
  margin: 0;
  width: 0;
  height: 0;
  display: none;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

:root {
  --spiner-bgc: white;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--spiner-bgc);
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.move-page-down-header {
  margin-top: 9.26vh;
}

.marketplace-modal-left-col {
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex: 1;
  height: 400px;
}

.marketplace-modal-right-col {
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 400px;
}

.marketplace-modal-token-name {
  text-align: center;
  color: white;
  display: block;
  font-size: 40px;
}

.marketplaceCardModalImage {
  max-width: 100%;
  max-height: 100%;
}

.marketplace-modal-trait-row {
  display: flex;
}

.connect-modal-logo {
  height: 200px;
}




.connected-wallet-info-address:hover {
  cursor: pointer;
}

.connected-wallet-modal-disconnect {
  background-color: red !important;
  height: 60px;
  border-color: black;
  border-width: 3px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border-width: 4px;
  border-style: solid;
  font-family: nowbold;
  padding: 10px;
}

.connect-wrong-network {
  display: flex;
  border-radius: 50px;
  background-color: red;
  color: white;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 20px;
  padding: 0px 10px 3px 10px;
  height: 70px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.banner {
  width: 100%;
}

/*Banner max a max height of 500 px and will cut off bottom if banner is normally too big while keeping aspect ratio*/
.banner2 {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.newsticker {
  position: absolute;
  right: -13px;
  top: -30px;
  width: 60px;
}

.myboomers-boomertext {
  height: 100px;
  margin-top: 20px;
}

.myboomers-curlywave {
  height: 100px;
}

.myboomers-boomercounttext {
  font-size: 50px;
  font-family: nowbold;
  color: #f083d4;
}

.boomer-display-grid {
  margin: 50px;
  display: flex;
  justify-content: center;
}

/*CHASE DOT CHASE DOT CHASE DOT*/

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {

  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1.0);
  }
}

.spinner-zone-temp {
  height: 300px
}

.h {
  height: 60vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.marketplace-card-rank-container {
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  justify-content: center;
  height: 115px;
}

.marketplace-card-rank {
  background-color: #3fa11f;
  border-radius: 8px;
  border-style: solid;
  border-color: #000000;
  margin: 5px;
  padding: 2px;
  color: rgb(238, 238, 238);
  font-family: nowbold;
  font-size: 15px;
  width: 105px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-shadow {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.Sales {
  background-color: crimson;
  /* color: #0c1052; */
  color: white;
}

.selected {
  box-shadow: 0px 0px 0px 4px rgb(16, 184, 38);
  /* border-radius: 10px; */
  border-radius: 10px;
  border-color: #000000;
  border-width: 1px;
}

.bigger-on-hover {
  transition: all .1s ease-in-out;
}

.bigger-on-hover:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
}


.underline {
  text-decoration: underline;
}

.flex-and-center {
  display: flex;
  justify-content: center;
  align-items: center;

}

.flex-column {
  flex-direction: column !important
}

.margin-20 {
  margin: 20px;
}

.margin-10 {
  margin: 10px;
}

.underline {
  text-decoration: underline;
}

.black {
  color: rgb(0, 0, 0) !important;
}

.balance-row {
  display: flex;
  align-items: center;
  margin: 10px;
}

.connected-wallet-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* background-color: #051221; */
  /* background-color: #030a11; */
  padding: 0px 15px;
  border-radius: 10px;
  color: white;
  font-family: nowbold;
}

.connected-wallet-info-cronoslogo {
  height: 50px;
  margin-right: 5px;
}

.connected-wallet-info-address {
  display: flex;
  align-items: center;
  color: white;
  border-width: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-size: 15px;
  /* background-color: #051221; */
  font-family: nowbold;

  border-style: solid;
  background-color: #759AAB;
  border-color: #fff2cc;
  border-width: 2px;
  border-radius: 30px;
  padding: 5px;
  height: 35px;
}

.App {
  display: flex;
}

.transition {
  transition: all 0.3s;
}

.flex {
  display: flex;
}

.home-section {
  max-width: 85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5vh;
  text-align: center;
}

.mission-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  align-items: center;
  font-size: 16px;
  font-size: 1vw;
  line-height: 300%;
  margin: 40px;
  gap: 20px
}

.mission-section-textonly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  color: white;
  font-size: 20px;
  /* line-height: 100%; */
  gap: 40px;
  height: 100%;
  font-family: nowbold;
  text-align: center;
}

.big-flashy-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mission-section-small {
  flex: 1;
}

.mission-section-big {
  flex: 2;
}

.mission-section-medium {
  flex: 1.5;
}

.mission-section-image-small {
  height: 18vw;
}

.mission-section-image {
  max-height: 40vw;
  border-radius: 6px;
  border: #fff2cc solid 5px;
}

.mission-section-image-small {
  max-height: 300px;
  border-radius: 6px;
  border: #fff2cc solid 5px;
  margin: 5px;
}

.text-60 {
  font-size: 60px;
}

.text-50 {
  font-size: 50px;
}

.text-40 {
  font-size: 40px;
}

.VDStats-box {
  display: flex;
  align-items: center;
  width: 700px;
  background-color: rgb(255, 242, 204);
  border-radius: 60px;
  box-shadow: 0px 0px 20px rgba(255, 242, 204, 0.5);
  text-align: center;
  padding: 40px;
  gap: 15px;
  font-family: nowbold;
  color: #000000;
  font-size: 15px;
  margin: 20px;
}

.mobile-VDStats-box {
  display: flex;
  align-items: center;
  width: 350px;
  background-color: rgb(255, 242, 204);
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(255, 242, 204, 0.5);
  text-align: center;
  padding: 10px;
  gap: 10px;
  font-family: nowbold;
  color: #000000;
  font-size: 15px;
  margin: 10px;
}

.VDStats-box-title {
  font-size: 40px;
  color: #0b918f;
  text-shadow: 2px 2px #000000;
}

.mobile-VDStats-box-title {
  font-size: 20px;
  color: #0b918f;
  text-shadow: 2px 2px #000000;
}

.VDStats-box-splitsection {
  display: flex;
  width: 100%;
}

.VDStats-box-half {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.VDStats-box-subtitle {
  font-size: 30px;
  text-decoration: underline;
}

.mobile-VDStats-box-subtitle {
  font-size: 20px;
  text-decoration: underline;
}

.VDStats-box-subtitle.deposited {
  color: #00a919;
  text-shadow: 1px 1px #000000;
}

.VDStats-box-subtitle.withdrawn {
  color: #be5500;
  text-shadow: 1px 1px #000000;
}

.mobile-VDStats-box-subtitle.deposited {
  color: #00a919;
  text-shadow: 1px 1px #000000;
}

.mobile-VDStats-box-subtitle.withdrawn {
  color: #be5500;
  text-shadow: 1px 1px #000000;
}

.cronoslogo {
  height: 30px;
  border-radius: 1000px;
}

.withdraw-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00620f;
  margin: 15px;
  padding: 15px;
  border-radius: 40px;
  width: 200px;
  font-family: nowbold;
  border-color: rgb(44, 44, 44);
  border-width: 3px;
  border-style: solid;
  color: white;
}

.mobile-withdraw-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00620f;
  margin: 10px;
  padding: 10px;
  border-radius: 30px;
  width: 150px;
  font-family: nowbold;
  border-color: rgb(44, 44, 44);
  border-width: 2px;
  border-style: solid;
  color: white;
}

.VDStats-box-line {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 200px;
}

.mobile-VDStats-box-line {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 138px;
}

.disclaimer-container {
  max-width: 700px;
  margin: 50px;
  font-family: nowbold;
}

.disclaimer-title {
  font-size: 60px;
  background-color: red;
  padding: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 50px red;
}

.disclaimer-text {
  font-size: 25px;
  background-color: white;
  color: #e20000;
  padding: 40px;
  border: 4px dashed red;
  gap: 30px;
  box-shadow: 0px 0px 50px red;
}

.carousel-head {
  height: 750px;
  width: 3120px;
  overflow-x: hidden;
  margin-left: calc((100% - 3120px) / 2);
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 5px;
}

.mobile-carousel-head {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.carousel-card {
  width: 1040px;
  height: 700px;
  transition: all 0.15s;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* Updated this line */
  background-position: center !important;
  /* Optional: to center the image */
}

.mobile-carousel-card {
  width: 340px;
  height: 230px;
  margin: 0px 5px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px;
  background-size: contain;
  margin-bottom: 20px;
}

.mobile-carousel-image {
  height: 230px;
  width: 340px;
  border-radius: 10px;
}

.carousel-card.inactive {
  transform: scale(0.9);
  opacity: 40%;
  transition-property: all;
  transition-duration: 0.5s;
}

.carousel-card.inactive:hover {
  opacity: 50%;
  transform: scale(0.95);
  cursor: pointer;
}

.carousel-arrow {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  background-color: #0e0d0d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: rgb(88, 88, 88) solid 1px;
  transition: all 0.3s;
  pointer-events: auto;
}

.carousel-arrow:hover {
  transform: scale(1.2);
  transition: all 0.3s;
  cursor: pointer;
  background-color: #1f1d1d;
}

.arrow-left {
  height: 15px;
  width: 15px;
  border-top: rgb(218, 218, 218) 5px solid;
  border-left: rgb(218, 218, 218) 5px solid;
  transform: rotate(-45deg);
}

.arrow-right {
  height: 15px;
  width: 15px;
  border-top: rgb(218, 218, 218) 5px solid;
  border-left: rgb(218, 218, 218) 5px solid;
  transform: rotate(135deg);
}

.carousel-arrows-container {
  position: absolute;
  top: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1050px;
  pointer-events: none;
}

.carousel-buttons-container {
  position: absolute;
  top: 89%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1060px;
}

.frontpage-section-container {
  padding-top: 40px;
  width: 1280px;
}

.mobile-frontpage-section-container {
  padding-top: 40px;
  width: 360px;
}

.frontpage-section-title {
  font-family: nowbold;
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
}

.mobile-frontpage-section-title {
  font-family: nowbold;
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.explore-card {
  height: 300px;
  min-width: 350px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/*Dont scale image*/
.explore-card-image {
  width: 100%;
  height: 150px;
  border-radius: 5px;
}

.explore-card-title {
  font-family: nowbold;
  font-size: 33px;
  color: white;
}

.explore-card-subtitle {
  font-family: nowbold;
  font-size: 20px;
  color: rgb(155, 155, 155);
}

.frontpage-section {
  box-shadow: inset 0px 10px 20px rgba(0, 0, 0, 0.9);
  padding-top: 40px;
  padding-bottom: 60px;
  color: white;
}

.frontpage-section.one {
  background: radial-gradient(circle, rgb(35, 57, 130) 0%, rgba(5, 18, 33, 1) 89%);
}

.frontpage-section.two {
  background: radial-gradient(circle, rgb(111, 42, 139) 0%, rgb(17, 0, 33) 89%);
}

.carousel-button {
  background-color: #667483;
  border-radius: 10px;
  width: 100px;
  height: 15px;
}

.carousel-button:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.carousel-button.active {
  background-color: #ffffff;
  height: 20px;
}

.buy-card-image {
  height: 170px;
  width: 170px;
  border-radius: 5px;
}

.buy-card-collection-logo {
  height: 55px;
  width: 55px;
  border-radius: 10px;
}

.buy-card-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 10px;
}

.buy-card-collection-container {
  display: flex;
  justify-content: space-between;
}

.monochrome {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 30%;
}

.scratchbuy-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.scratchbuy-card-image {
  border-radius: 1000px;
  height: 170px;
  width: 170px;
}

.scratchbuy-card-title {
  font-family: nowbold;
  font-size: 35px;
  color: white;
}

.frontpage-list {
  margin-top: 30px;
}

.dropdown-navbar {
  position: relative;
  display: inline-block;
  color: white;
  font-family: nowbold;
  font-size: 30px;
  cursor: pointer;
  padding: 3px 20px;
  border-radius: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: -0px;
  width: 200px;
  z-index: 1;
}

.dropdown-invisible {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
}

.dropdown-visible {
  padding: 12px 16px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: rgb(3, 10, 17, 97%);
}

.dropdown-navbar:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-navbar:hover .dropdown-content {
  display: block;
}

.dropdown-arrow {
  border-right: rgb(145, 145, 145) 3px solid;
  border-top: rgb(145, 145, 145) 3px solid;
  height: 10px;
  width: 10px;
  transform: rotate(135deg);
  margin-left: 10px;
}

.dropdown-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: nowbold;
  text-align: center;
  font-size: 15px;
  width: 190px;
  height: 35px;
  border-bottom: 1px solid rgba(145, 145, 145, 0.442);
}

/* Mobile styles for when width is less than 1200 */
@media only screen and (max-width: 1200px) {
  .dropdown-item {
    font-size: 15px;
  }
}


.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-blue-arrow {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid rgb(3, 10, 17, 97%);
}

.merch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 50px 0px;
  box-shadow: inset 0px 10px 20px rgba(0, 0, 0, 0.9);
}

.merch-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  font-family: nowbold;
  width: 350px;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  height: 100px;
  font-size: 20px;
  box-shadow: 0px 10px 20px rgba(17, 17, 17, 0.9);
}

.merch-logo {
  max-height: 100%;
}

.my-row {
  display: flex;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
}

.boomerarcade-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;
  font-size: 30px;
  cursor: pointer;
}

.boomerarcade-logo:hover {
  color: #FFD700;
}

.boomerarcade-logo-accent {
  color: #FFD700;
}