.zoomer-all-container{
    display: flex;
    justify-content: center;
    gap: 20px;
    font-family: nowbold;
    margin: 50px 0px;
}

.zoomer-section-container{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 600px;
    min-width: 600px;
    overflow: hidden;
    gap: 10px;
}

.zoomer-section-image{
    width: 100%;
    border: 2px solid #202020;
    border-radius: 5px;
}

.zoomer-trait-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    border: 2px dashed #091037;
    width: 165px;
    border-radius: 5px;
    padding: 5px;
}

.zoomer-trait-box-type{
    color:#2f91a0;
    font-size: 15px;
}

.zoomer-trait-box-value{
    font-style: italic;
    font-size: 12px;
}

.zoomer-trait-box-rarity{
    color:rgb(208, 0, 174);
    font-size: 10px;   
}

.zoomer-attributes-container{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.zoomer-collection-symbol-container{
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 100px;
    background-color: rgb(2, 7, 28);
    border: 2px solid black;
}

.zoomer-section-row{
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.zoomer-section-name{
    font-size: 30px;
}

.zoomer-section-info-blob{
    font-size: 50px;
}

/* Main container */
.test-container {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: fit-content;
}
  
/* Title */
.test-title {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    text-align: left;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
    background-color: #e0e0e0;
    margin-bottom: 3px;
}

/* Description */
.test-description {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    font-style: italic;
    font-size: 11px;
    color: #666;
    text-align: left;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 4px;
    background-color: #ffffff;
}

.zoomer-info-container{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.zoomer-section-box{
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #232323;
    border-radius: 5px;
    gap: 20px;
}

.zoomer-special-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 130px;
    min-width: 130px;
    max-width: 130px;
    height: 60px;
    border-radius: 10px;
    border: 2px solid white;
    box-shadow: 0px 8px #9d9d9d;
    cursor: pointer;
    transition: all .1s ease-in-out;
    font-size: 12px;
    margin-bottom: 10px;
    color: white;
    text-align: center;
}

.zoomer-special-button:hover{
    transform: translateY(7px);
    box-shadow: 0px 1px #9d9d9d;
    transition: all .1s ease-in-out;   
}

.zoomer-special-button.green{
    background-color: rgb(0, 163, 0);
}

.zoomer-special-button.lb{
    background-color: rgb(0, 147, 180);
}

.zoomer-special-button.disabled{

}

.zoomer-quickbox{
    border: 1px dashed grey;
    padding: 2px;
    border-radius: 5px;
}

.dis-disclaimer{
 border: 5px dashed red;
 background-color: #fff2cc;
 color: black;
 max-width: 1200px;
 width: 100%;
 border-radius: 10px;
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 10px;
 margin-bottom: 20px;
}


.my-modal{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
    justify-content: center;
    align-items: center;
    font-family: nowbold;
}

.my-modal-box{
    background-color: #f2f2f2;
    min-height: 200px;
    min-width: 200px;
    max-width: 358px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    border: 3px solid rgb(23, 156, 171);
    gap: 20px;
}

.my-modal-title{
    font-size: 25px;
}

.my-modal-title.success{
    color:rgb(0, 163, 0)
}

.my-modal-title.error{
    color: red;
}

.my-modal-box-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
}

.my-modal-box-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.my-modal-box-item-image{
    width: 150px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid black;
}

.my-modal-danger{
    border: 2px red dashed;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}











































/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/
/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/
/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/
/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/
/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/
/*MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE*/

.m-zoomer-all-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-family: nowbold;
    margin: 50px 0px;
}

.m-zoomer-section-container{
    display: flex;
    flex-direction: column;
    max-width: 355px;
    width: 355px;
    min-width: 355px;
    overflow: hidden;
    gap: 10px;
}
  