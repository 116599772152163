@font-face {
  font-family: nowbold;
  src: url("https://boomersquad-web-images.s3.amazonaws.com/public/now.bold-webfont.woff")
}

.mobile-fixed-header  {
    display:flex;
    width:100%!important;
    background-color: #030a11;
    height: 60px!important;
    align-items: center!important;
    justify-content: space-between!important;
    flex-direction: column;
    overflow: hidden;
    padding-top: 5px;
}

.mobile-glass-container {
  background: rgb(255, 242, 204, 0.3);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border-radius: 10px;
  border: 2.5px solid rgba( 255, 255, 255, 0.18 );
  height: auto;
  width: 355px;
  align-items: center;
  justify-content: center;
  
}

.mobile-connected-wallet-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* background-color: #051221; */
  background-color: #030a11;
  color: white;
  font-family: nowbold;
  height: 50px;
  font-size: 12px;
}

.mobile-balance-row{
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  height: 25px;
  width: 110px;
}

.mobile-connected-wallet-info-cronoslogo {
  height: 23px;
  width: 23px;
  margin-right: 5px;
}

.mobile-header-logo  {
    height: 50px;    
}

.mobile-horizontal-teal-line-header {

    background-color: #70abaf; 
    height: 3px;
    width: 100%;
}

.mobile-horizontal-teal-line-footer {

    background-color: #70abaf; 
    height: 5px;
    width: 100%;
  
  }

.mobile-container2 {
    position: relative;
    text-align: center;
    color: #051221; 
}

.mobile-sloganPic{
    width: 100vw;
    overflow-x: hidden;
}

.mobile-slogan{
    font-size: 23px;
    /*font-family: nowbold;*/
    font-family: nowbold;
    text-align: center;
    height: 40px;
    font-weight: bold;

}

.mobile-container {
    overflow: hidden;
    mask-clip: content-box;
    mask-image: linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 95%, rgba(0,0,0,0) 100%);
}

.mobile-sliding-background {
    /* background-image: 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/1.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/2.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/3.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/4.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/5.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/6.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/7.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/8.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/9.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/10.png"); */

      background-image: 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/1.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/2.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/3.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/4.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/5.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/6.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/7.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/8.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/9.png"), 
      url("https://boomersquad-web-images.s3.amazonaws.com/public/slidingbackground/10.png");
    background-repeat: no-repeat;
    background-size: 250px 250px;
    height: 265px;
    width: 2475px;
    background-position: 0px bottom, 275px bottom, 550px bottom, 825px bottom, 1100px bottom, 1375px bottom, 1650px bottom, 1925px bottom, 2200px bottom, 2475px bottom;
    animation: mobile-slide 60s linear infinite;
}

@keyframes mobile-slide{
    0% {    
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-1400px, 0, 0);
    }
}

.mobile-title{
  font-size: 40px;
  font-family: nowbold;
  /* color: #f1f1f1;
   */
  color:  #fff2cc;
  text-align: center;
  border-radius: 20px;
  position: relative;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  
}

.mobile-title-sub {
  font-size: 20px;
  font-family: nowbold;
  color:  #fff2cc;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 20px;
}

.mobile-title-mission {
    margin:15px 0px;
    font-size: 40px;
    font-family: nowbold;
    color: #051221;
    text-align: center;
    background: #fff2cc;
    padding: 5px;
    border-radius: 10px;
    width: 360px;
    height: auto;
    position:relative;
    justify-content: center;
}

.mobile-mission-blue-one {
    font-size: 16px;
    background-color: #051221;
    border-radius: 10px;
    color: white;
    justify-content: center;
    padding: 5px;
    align-items: center;
  }

.mobile-mission-blue-two {
font-size: 17px;
background-color: #051221;
border-radius: 10px;
color: white;
justify-content: center;
display: flex;
padding: 20px;

}
/* 
.mobile-mission-middle {
font-size: 10px!important;
color: #051221;
height: 50px;
width: 100%;
margin-top:15px;
justify-content: center;
justify-items: center;
align-content: center;
align-items: center;
} */

.mobile-ref-point {
    height: 60px;
    position:relative;
    width:360px;
  }

  .mobile-mission-title-sub {
    display: flex;
    font-size: 25px;
    font-family: nowbold;
    color: #051221;
    text-align: center;
    margin-top:20px;
    align-items: center!important;
    align-content: center!important;
    background-color:#70abaf;
    border-radius: 10px;
    color: #051221;
    height: 50px;
    justify-content: center;
    width: 100%;
  }
  .mobile-mission-title-sub-sub {
    font-size: 20px;
    background-color:hsl(184, 28%, 56%, 0.3);
    border-radius: 10px;
   
    
    
  }

  .mobile-mission-sub-sub {
    font-size: 15px;
    margin-bottom:30px;
    margin-top: 10px;
  }

  .mobile-infograph-vertical-divider-white {
    width: 5px;
    background-color: white; 
  
    align-self: center;
    border-radius: 50px;
    height: 30px;
    margin: 10px 0;
  }

  .mobile-roadmap-image {
      width: 100%;
  }

  .mobile-story-image-one {
    position: absolute;
    height: 100px;
    left: 80%;
    top: 45%;
    z-index: 1;
  }

  .mobile-story-image-two {
    position: absolute;
    height: 100px;
    left: 80%;
    top: -1.8%;
    z-index: 1;
  }

  .mobile-story-image-col {
    width: 350px;
  }

  .mobile-story-button {
  
    height: 45px!important;
    width: 350px!important;
    background-color: #70abaf !important;
    color: #051221!important;
    border-radius: 30px!important;
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #535353!important;
    justify-content: center;
    font-size: 20px!important;
  
  }

  .mobile-story-button:hover {
  
    transform:scale(1.025)
  
  }

  .mobile-story_img {
   
    width:100%;
    object-fit:fill;
    border-style: solid;
    border-width: 2px;
    border-color:#051221;

  }
  .mobile-story_img.none {
   
   display:none;

  }

  .mobile-story-image-row {
    display:flex !important;
    margin-top: 3px;
    justify-content: space-between!important;
    justify-items: space-between!important;
    gap: 3px;
  }

.mobile-meet-member-short {
    margin: 5px;
    padding: 5px;
    width: 100px;
    height: 170px;
    background: rgb(112, 171, 175, 0.7);
    box-shadow: 2px 2px 8px 2px rgb(5, 18, 33, 1);
    border-radius: 10px;
    justify-content: center;
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }

  .mobile-meet-member-short-artist {
    margin: 5px;
    padding: 5px;
    width: 100px;
    height: 170px;
    background: rgb(91, 176, 197);
    box-shadow: 2px 2px 8px 2px rgb(5, 18, 33, 1);
    border-radius: 10px;
    justify-content: center;
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }

  .mobile-meet-member-short-artist:hover {
    transform: scale(1.03);
    transition: all 0.2s;
    cursor:pointer;
  }

  .mobile-meet-member-short:hover {
    transform: scale(1.03);
    transition: all 0.2s;
    cursor:pointer;
  }

  .mobile-meet-member-full {
    margin: 5px;
    padding: 5px;
    width: 320px;
    height: 170px;
    background: rgb(112, 171, 175, 0.7);
    box-shadow: 2px 2px 8px 2px rgb(5, 18, 33, 1);
    border-radius: 15px;
    justify-content: space-between;
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  }

  .mobile-meet-member-full:hover {
    transform: scale(1.03);
    transition: all 0.2s;
    cursor:pointer;
  }

  .mobile-team-row-name {
    /* font-size: 8.6px; */
    font-size: 14px;
    justify-content: center;
    align-items: center;
    width: 160px;
  }
  
  .mobile-team-row-title {
    font-size: 14px;
    justify-content: center;
    align-items: center;
    width: 160px;

  }

  .mobile-team-row-aka {
    font-size: 13px;
    height: 23px;
    justify-content: center;
    align-items: center;
  
  }

  .mobile-team-row-bio {
    font-size: 8px;
    width: 210px;
    justify-content: center;
    align-items: center;
    text-align: left;
  
  }

  .mobile-team-row{
    justify-content: center;
    width: 355px;
    gap: 30px;
  }

  .mobile-meet-member-artist-link {
    color:white;
  }

  .mobile-team-col-right {
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction: column;
  }

  .mobile-team-col-left {
    width:90px;
  }
  
  .mobile-team-row-image {
    height: 80px;
  }

  .mobile-faq-row {
    justify-content: center;
  }
  
  .mobile-faq-row-question {
    font-size: 18px;
    width: 340px;
    margin-bottom: 5px;
    
  }
  
  .mobile-faq-row-answer {
    font-size: 11px;
    width:340px;
    margin-top: 5px;
  }
  
  .mobile-faq-row-divider {
    height: 2px;
    background-color: #051221;
    border-radius: 50px;
    width: 100px;
  }

  .mobile-onepager-footer {
    background-color: #051221;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 25px 0px;
  }

  .mobile-footer-extras-link {
    font-size: 20px;
    margin: 5px 15px;
    color: #70abaf;
  }

.mobile-faq-image-one {
    position: absolute;
    height: 120px;
    left: 76%;
    bottom: 90%;
    z-index: 1;
  }

  .mobile-team-image-one {
    position: absolute;
    height: 100px;
    left: 0%;
    bottom: -90%;
    z-index: 1;
  }

  .mobile-team-image {
    height: 120px;
    border-radius: 100px;
  }

  .mobile-roadmap-image-one {
    position: absolute;
    height: 70px;
    left: -9%;
    top: -0.75%;
    z-index: 1;
  }

  .mobile-mission-image-one {
    height: 170px;
    margin: 10px 0px;
  }
  
  .mobile-mission-image-two {
    height: 170px;
    margin-bottom: -117px;
    margin-top: 15px;
  }

  .mobile-faq-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 20px 0px;
    border-radius: 10px;
    max-width: 355px;
  }

  .mobile-mission-sticker {
    height: 170px;
    margin: 10px 0px;
  }

  .mobile-footer-legal {
    justify-content: center;
    align-items: center;
    text-align: center;
    width:300px;
    margin-bottom: 20px;
  }

  .mobile-footer-socials-logo {
    height: 35px;
    margin: 0px 20px;
}

.burger {
  overflow-y: scroll;
  position: fixed;
  background-color: #030a11;
  left: -320px;
  height: 100vh;
  transition-duration: 0.5s;
  z-index: 2;
  width:320px;
}

.burger.showBurger {
  transform:translateX(320px);
  transition-duration: 0.5s;
}

.burger.hideBurger {
  transform:translateX(-320px);
  transition-duration: 0.5s;
}

.burgerIconLayer {
  flex: 1;
  width: 100%;
  flex:1;
  border-radius: 5px;
}

.burgerIconLayer.white {
  background-color: white;
}

.burgerIconLayer.red {
  background-color: rgb(138, 39, 39);
}

.mobile-burgerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45px;
  height: 40px;
  margin-left: 5px;
}
.burgerContainer.insideBurger {
  margin-right: 0px;
}

.burgerContainer:hover {
  cursor:pointer;
}

.container-in-burger {
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.burger-socials {
  height: 40px;
}

.burger-header {
  display: flex;
  height:65px;
  align-items: center;

  
}

.burger-extras{
  font-size: 16px;
  font-family: nowbold;
  text-shadow: 0px 0px 2px rgb(0, 0, 0);
}

.burger-extras.bgreen{
  color:#5fc92e;
}

.burger-extras.borange{
  color:#e47e1e;
}

.burger-extras.bred{
  color:#a74345;
}

.burger-extras.bpink{
  color:#c77d7e;
}

.burger-extras.bpurple{
  color:#8c6b87;
}

.burger-extras.broom{
  color:#7015a8;
}

.burger-extras.bblue{
  color:#2640b3;
}

.burger-extras.bteal{
  color:#70abaf;
}

.mobile-move-page-down-header {
  margin-top: 0px;
}

.mobile-please-connect-wallet-image {
  margin-top: 200px;
  width: 360px;
}

.mobile-mint-info {
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: center;
  width: 360px;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.mobile-mint-image {
  width: 240px;
  
}

.mobile-myboomers-boomertext {
  height: 40px;
  margin-top: 20px;
}

.mobile-myboomers-curlywave {
  height: 40px;
}

.mobile-myboomers-boomercounttext {
  font-size: 20px;
  font-family: nowbold;
  color: #f083d4;
}

.mobile-myboomers-boomercounttext-VD {
  font-size: 14px;
  font-family: nowbold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-marketplace-trait-col {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 1px;
  margin: 1px;
  background-color: rgb(221, 221, 221);
  color: #051221;
  font-family: Roboto Mono;
  height: 49px;
  width: 80px;

}

.mobile-marketplace-modal-token-name {
  text-align: center;
  color: white;
  display: block;
  font-size: 20px;
  margin: 5px 0px;
}

.mobile-marketplace-trait-row-trait{
  align-Items: center !important;
  justify-content: center !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  color: #759aab!important;
  font-weight: bold !important;
  font-size: 9px;
}
.mobile-marketplace-trait-row-trait-type{
  align-Items: center !important;
  justify-content: center !important;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bolder !important;
  font-size: 10px!important;
  overflow: hidden;
}
.mobile-marketplace-trait-row-trait-percentile{
  align-Items: center !important;
  justify-content: center !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold!important;
  color: #535353;
  font-size: 8px;
}

.mobile-selectmint-button {
  width: 300px;
  margin: 30px;
  transition: all .2s ease-in-out;
}

.mobile-selectmint-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-selectmint-button:hover {
  cursor: pointer;
  transform: scale(1.07);
  transition: all .2s ease-in-out;
}

.mobile-publicmint-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color:#ffffff;
  font-size: 22px;
  font-family: nowbold;
  margin: 20px 0px;
}

.mobile-publicmint-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 0px 10px;
  height: 40px;
  background-color: #3fa11f;
  margin-right: 40px;
  border-radius: 5px;
  font-family: nowbold;
  font-size: 25px;
}

.mobile-chowtimemint-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 0px 10px;
  height: 40px;
  background-color: #a74345;
  margin-right: 40px;
  border-radius: 5px;
  font-family: nowbold;
  font-size: 25px;
}

.mobile-publicmint-button {
  cursor: pointer;
}

.mobile-wlmint-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  padding: 0px 10px;
  height: 40px;
  background-color:#1b62a3;
  margin-right: 40px;
  border-radius: 5px;
  font-family: nowbold;
  font-size: 25px;
}

.mobile-wlmint-button:hover {
  cursor: pointer;
}

.mobile-no-boomers-found-image {
  width: 300px;
  margin: 100px 0px;
}

.mobile-connect-modal-logo {
  height: 90px;
  margin: 3px;
}

.burger-boomerdocs {
  font-size: 15px;
  color:#70abaf;
  font-family: nowbold;
  margin: 0px 10px;
}

.mobile-h {
  width: 90vw;
  margin: 4vh 0;
}

.mobile-no-h {
  height: 50vh;
  margin: 4vh 0;
}

.mobile-newsticker{
  position: absolute;
  right: -25px;
  top: 5px;
  width: 30px; 
}

.mobile-chowtimemint-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color:#ffffff;
  font-size: 22px;
  font-family: nowbold;
  background-color: #c77d7e;
}

.mobile-chowtime-logo {
  width: 350px;
  margin: 15px 0px 20px 0px;
}

.mobile-chowtime-mint-select-button{
  width: 150px;
  height: 150px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: nowbold;
  font-size: 20px;
  color: white;
  border-radius: 20px;
  border-style: solid;
  border-color: #000000;
}

.mobile-chowtime-item-inputsquare-container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #051221;
  /* height: 180px; */
  /* width: 860px; */
  padding: 10px;
  border-radius: 10px;
  border-color: #051221;
  border-width: 5px;
  border-style: solid;
  margin: 0px 0px 20px 0px;
  width: 350px;
}

.mobile-chowtime-item-inputsquare{
  width: 150px;
  height: 150px;
  border-radius: 10px;
  /* border-style: solid;
  border-color: black; */
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: nowbold;
  margin: 5px;
}

.mobile-chowtime-item-inputsquare-filled{
  width: 150px;
  height: 150px;
  border-radius: 10px;
  /* border-style: solid;
  border-color: black; */
  font-size: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: nowbold;
  margin: 5px;
}

.mobile-chowtime-listings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  min-height: 200px;
  /* background-color: #f1f1f1; */
  
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(1, 207, 214);
  border-width: 2px;
  margin: 10px;
  padding: 10px;
  /* background-color: #feffcc; */
  background-color: #051221;
}

.mobile-chowtime-market-button {
  margin: 10px;
  font-size: 30px;
  font-family: nowbold;
  color: white;
  border-radius: 10px;
  border-style: solid;
  padding: 10px;
  width: 250px;
  text-align: center;
}

.mobile-chowtime-valuecard-item-container{
  position: relative;
  text-align: center;
  color: white;
  width: 350px;
  margin: 5px;
}

.mobile-chowtime-valuecard-currentValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #051221;
  border-radius: 5px;
  border-color: #051221;
  border-style: solid;
  border-width: 1px;
  min-height: 50px;
  min-width: 20px;
  font-family: nowbold;
  color:#ffffff;
  font-size: 15px;
  padding: 3px;
}

.mobile-bottom-right {
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.mobile-chowtime-valuecard-supply{
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #c77d7e;
  border-radius: 10px;
  border-width: 2px;
  min-height: 50px;
  min-width: 20px;
  font-family: nowbold;
  color:#ffffff;
  font-size: 28px;
  padding: 5px;

}

.mobile-chowtime-buyarray-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f1f1f1; */
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(1, 207, 214);
  border-width: 2px;
  margin: 10px;
  width: 320px;
  padding: 10px;
  /* background-color: #feffcc; */
  background-color: #051221;
}

.mobile-chowtime-marketplace-container-buys {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: top;
  width: 320px;
  min-height: 200px;
  /* background-color: #f1f1f1; */
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(1, 207, 214);
  border-width: 2px;
  margin: 10px;
  padding: 10px;
  /* background-color: #feffcc; */
  background-color: #051221;
}

.mobile-max-width {
  width: 340px;
}

.mobile-disclaimer-box {
  background-color: rgb(238, 238, 238);
  border-style:dashed;
  border-color: red;
  border-width: 5px;
  padding: 10px;
  width: 350px;
}

.mobile-disclaimer-item {
  font-size: medium;
  margin-top:20px;
  margin-bottom:20px;
  
}

.mobile-disclaimer{
  font-size: 45px;
  color: crimson;
  text-align: center;
  font-family: nowbold;
}

.mobile-spaced-out{
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.mobile-middle-position{
  position:absolute;
  left: 9%;
  top: 6%;
}

.mobile-chowtime-history-container {
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  /* align-items: center; */
  min-width: 350px;
  min-height: 200px;
  /* background-color: #f1f1f1; */
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(1, 207, 214);
  border-width: 2px;
  margin: 0px 0px 10px 0px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: #2eddfc; */
  background-color: #051221;
  color:white;
  text-align: left;
  font-family: nowbold;
}

.mobile-onefour{
  position:absolute;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-twofour{
  position:absolute;
  left: 31%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-threefour{
  position:absolute;
  left: 59%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-fourfour{
  position:absolute;
  left: 83%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-chowtime-history-item{
  width: 100%;
  position: relative;
  height: 60px;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin:1px;
}

.mobile-onefourtitle{
  position:absolute;
  left: 2%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-twofourtitle{
  position:absolute;
  left: 31%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-threefourtitle{
  position:absolute;
  left: 56%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-fourfourtitle{
  position:absolute;
  left: 86%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-purchasehistory-amount{
  font-size: 18px;
  font-family: nowbold;
}

.mobile-purchasehistory-totalprice{
  font-size: 14px;
  font-family: nowbold;
}

.mobile-purchasehistory-itemprice{
  font-size: 14px;
  font-family: nowbold;
}

.mobile-purchasehistory-txhash{
  font-size: 12px;
  font-family: nowbold;
}

.mobile-chowtime-history-mini-picture{
  height:40px;
  border-radius: 5px;
  margin:2px;
}

.mobile-marketplace-card-rank{
  background-color: #3fa11f;
  border-radius: 8px;
  border-style: solid;
  border-color: #000000;
  margin: 5px;
  padding: 2px;
  color: rgb(238, 238, 238);
  font-family: nowbold;
  font-size: 15px;
  width: 105px;
  justify-content: center;
  align-items: center;
  text-align: center; 
}

.mobile-navbar-banner-section{
  width: 150px;
  height: 75px;
  margin-top: 4px;
  margin-bottom: 4px;
  position: relative;
  cursor: pointer;
  
 
}

.mobile-navbar-banner-section-half{
  width: 150px;
  height: 50px;
  margin-top: 4px;
  margin-bottom: 4px;
  position: relative;
  cursor: pointer;
 
}

.mobile-navbar-banner-container{
  width: 150px;
  height: 75px;
  position: absolute;
  border-radius: 10px;
}

.mobile-navbar-banner-container-half{
  width: 150px;
  height: 50px;
  position: absolute;
  border-radius: 10px;
}

.mobile-navbar-section-text{
  display: flex;
  font-size: 16px;
  font-family: nowbold;
  z-index: 3;
  position: absolute;
  border-radius: 8px 0px;
  background-color: rgb(190, 120, 0);
  padding: 4px;
  color: white;
  min-width: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.mobile-navbar-section-text.boomers{
  background-color: rgb(190, 120, 0);
}


.mobile-navbar-section-text.boomrooms{
  background-color: rgb(90, 2, 114);
}

.mobile-navbar-section-text.launchpad{
  background-color: rgb(0, 152, 198);
}

.mobile-navbar-section-text.bsac{
  background-color: rgb(235, 1, 145);
}

.mobile-navbar-section-text.comic{
  background-color: rgb(2, 143, 0);
}

.mobile-navbar-section-text.roadmap{
  background-color: rgb(10, 29, 105);
}

.mobile-navbar-section-text.pawnshop{
  background-color: rgb(220, 0, 51);
}

.mobile-navbar-section-text.link{
  background-color: rgb(232, 232, 232);
  color: rgb(0, 0, 0);
}

.mobile-navbar-section-text.construction{
  opacity: 0.4;
}

.mobile-mission-section-image{
  max-height: 300px;
  border-radius: 5px;
  border: #fff2cc solid 2px;
}

.mobile-home-section{
  display: flex;
  width: 350px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  color: white;
  gap: 20px;
}

.mobile-mission-section-image-small{
  height: 150px;
  border-radius: 4px;
  border-radius: 5px;
  border: #fff2cc solid 1px;
}

.mobile-big-text-info{
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: nowbold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}