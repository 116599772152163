@import "~react-image-gallery/styles/css/image-gallery.css";

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.bazaar-item-focus-container{
    width:100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    box-shadow: inset 0px 10px 20px rgba(0,0,0,0.9);
    padding-top: 50px;
    padding-bottom: 50px;
}
.bazaar-item-focus-section-image{
    width: 400px;
    position: sticky;
    top: 85px;
    height: max-content;
}

.bazaar-item-focus-section-info-container{
    max-width: 800px;
    min-width: 350px;
    color: rgb(0, 0, 0);
    font-style: nowbold;
    padding: 3px;
    border-radius: calc(var(--borderRadius-two) + 3px);
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    background: rgb(60,42,204);
    background: linear-gradient(45deg, rgba(60,42,204,1) 25%, rgba(0,255,246,1) 50%, rgba(255,0,217,1) 75%);

    animation-name: gradient;
    animation-duration: 1s;
    animation-iteration-count: 1000;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.bazaar-item-focus-section-info{
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
    width: 100%;
    height: 100%;
    border-radius: var(--borderRadius-two);
    padding: 20px;
    gap: 12px;
}

.bazaar-item-focus-section-info-name{
    font-size: 30px;
    line-height: 1.1;
}

.bazaar-item-focus-section-info-project{
    font-size: 14px;
    font-family: nowbold;
    color:rgb(0, 127, 42);
}

.bazaar-item-focus-section-info-line{
    width: 100%;
    height: 1px;
    background-color: rgb(0, 0, 0, 0.3);
    border-radius: 5px;
    margin: 7px 0px;
}

.bazaar-item-focus-section-info-price{
    font-size: 40px;
    font-family: nowbold;
}

.bazaar-item-focus-section-info-currency{
    font-size: 20px;
    font-family: nowbold;
    margin-top: 10px;
}

.bazaar-item-focus-section-info-stock{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    line-height: 1.1;
    color: #dd246b;
    align-items: baseline;
}

.bazaar-item-focus-section-info-buy{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-color: darkorange;
    width: 200px;
    height: 30px;
    border-radius: 20px;
    font-family: nowbold;
    cursor: pointer;
    border: 1px solid rgb(188, 103, 0);
}

.bazaar-item-focus-section-info-buy:hover{
    box-shadow: inset 0px 0px 5px rgba(113, 45, 0, 0.7);

}

.bazaar-item-focus-section-info-extras{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    line-height: 1.2;
    font-size: 15px;
}

.bazaar-item-focus-section-info-details{
    margin-top: 20px;
    font-family: nowbold;
    font-size: 20px;
}

.bazaar-details-table{
    margin-top: 20px;
    font-family:Arial, Helvetica, sans-serif;
    width: 100%;
    font-size: 15px;

}

th, td {
    font-size: 14px;
    text-align: center;
    padding: 10px;
    border: 1px solid black;

}

th {
    background-color: #00bf4c;
}

tr:nth-child(even) {background-color: #dbdbdb;}
