.mobile-lp-nftbanner-line{
    height: 2px;
    width: 100%;
    background-color: azure;
}

.mobile-lp-nftbanner{
    border-width: 2px;
    border-color: azure;
    border-style: solid;
    border-top-width: 0px;
}

.mobile-lp-nft{
    width: 80px; 
}

.mobile-lp-description{
    text-align: center;
    font-size: 16px;
    color: azure;
    width: 340px;
    margin-top: 20px;
}

.mobile-lp-socials{
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 2px;
    margin-top: 20px;
    background-color: black;
    color: azure;
    font-family: nowbold;
    font-size: 15px;
    border-style: dashed;
    border-color: azure;
}

.mobile-lp-socials-logo{
    height:25px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mobile-lp-mintgif{
    width: 300px;
    margin-top: 20px;
}

.mobile-lp-mintdescription{
    text-align: center;
    margin-top: 30px;
    color: azure;
    font-family: nowbold;
    font-size: 16px;
    width: 350px;
}

.mobile-lp-mintdescription-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 350px;
}

.mobile-lp-mintrow{
    width: 350px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
}

.mobile-lp-roadmap{
    width: 350px;
    margin-bottom: 50px;
}

.mobile-lp-soldout{
    width: 200px;
    margin: 50px;
}

.m-row-answer{
    color: rgb(235, 235, 235);
    font-size: 14px;
    font-style: italic;
}