.lp-nft {
    width: 200px;
}

.lp-nftbanner {
    border-width: 5px;
    border-color: azure;
    border-style: solid;
    border-top-width: 0px;
}

.lp-nftbanner-line {
    height: 5px;
    width: 100%;
    background-color: azure;
}

.lp-description {
    font-size: 22px;
    color: azure;
    width: 1260px;
    margin-top: 30px;
}

.lp-mintrow {
    width: 1260px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 50px;
}

.lp-mintgif {
    width: 600px;
}

.lp-mintdescription {
    text-align: start;
    margin-left: 30px;
    color: azure;
    font-family: nowbold;
    font-size: 26px;
    width: 630px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lp-mintdescription-row {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px dashed grey;
    align-items: baseline;
    justify-content: space-between;
}

.row-answer {
    color: rgb(235, 235, 235);
    font-size: 23px;
    font-style: italic;
}

.lp-socials {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 5px;
    margin-top: 20px;
    background-color: black;
    color: azure;
    font-family: nowbold;
    font-size: 30px;
    border-style: dashed;
    border-color: azure;
}

.lp-socials-logo {
    height: 50px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.lp-roadmap {
    width: 1260px;
    margin-bottom: 50px;
}

.lp-mintdescription-fast {
    color: azure;
    font-family: nowbold;
    font-size: 25px;
}

.lp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    color: azure;
    font-family: nowbold;
    font-size: 16px;
    width: 160px;
    height: 120px;
    margin: 10px;
    padding: 8px;
}

.lp-green {
    color: azure;
    background-color: chartreuse;
}

.lp-orange {
    color: azure;
    background-color: rgb(210, 102, 0);
}

.lp-blue {
    color: azure;
    background-color: rgb(82, 233, 238);
}

.lp-pink {
    color: azure;
    background-color: rgb(190, 32, 132);
}

.lp-red {
    color: azure;
    background-color: rgb(165, 25, 25);
}

.lp-mint-inputnumber {
    height: 40px;
}

.lp-soldout {
    width: 400px;
    margin: 50px;
}

.lp-mintdescription-connectwallet {
    font-size: 70px;
    margin: 50px;
    text-align: center;
}

.lp-section {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
}

.lp-section-title {
    color: white;
    font-size: 35px;
    margin-bottom: 10px;
}

.lp-section-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.lp-stuff {
    display: flex;
    flex-direction: column;
    width: 1200px;
    align-items: center;
    gap: 20px;
}

.lp-row {
    display: flex;
    justify-content: center;
    font-family: nowbold;
    padding: 50px 0px;
}

.lp-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 165px;
    width: 165px;
    overflow: hidden;
    border-radius: 10px;
}

.lp-section-image {
    height: 100%;
}

.lp-section-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.lp-section-name {
    color: grey;
    font-size: 16px;
    margin-bottom: 10px;
}

.lp-viewcollection-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px 10px 30px 10px;
    background-color: rgb(117, 181, 202);
    border: 1px solid beige;
    border-radius: 10px;
    font-size: 20px;
    font-family: nowbold;
    color: black;
    min-width: 200px;
}